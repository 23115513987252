import {
  isNil,
  mapValues,
  omitBy,
  pickBy,
} from 'lodash';
import {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  ButtonGroup,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getTemperatures, getConfig } from '../api/config';
import { getReport } from '../api/logs';
import { ReactComponent as MenuIcon } from '../assets/menu.svg';
import http from '../config/axios';
import { useFullObjectsData } from '../queries';
import { setShowConfigSidebar } from '../store/reducers/appSettings';

export const SidebarObject = () => {
  const dispatch = useDispatch();
  const [settingsValues, setSettingsValues] = useState({});
  const { id } = useParams();
  const aliasRef = useRef();

  const {
    currentKT,
    currentSilos,
    loading,
    volumes,
  } = useSelector(({ config }) => config);
  const { object, objects } = useSelector(({ object }) => object);
  const { companyId } = useSelector(({ company }) => company.current);
  const { showConfigSidebar } = useSelector(({ appSettings }) => appSettings);
  const { loading: logsLoading } = useSelector(({ logs }) => logs);

  const objectsIds = useMemo(() => objects
    && objects?.map((object) => object.object_id), [objects]);

  const objData = useFullObjectsData(objectsIds);

  const temperatures = objData?.data
    ?.filter((o) => o.object_id === Number(id)).find((o) => o.name === currentSilos?.name);

  useEffect(() => {
    setSettingsValues((prev) => ({
      ...prev,
      [currentKT?.addr]: prev[currentKT?.addr] ?? {
        saved: true,
        alias: currentKT?.alias,
        culture: currentKT?.culture,
        danger: currentKT?.danger,
        warning: currentKT?.warning,
        sensors_gap: currentKT?.sensors_gap,
        height: currentKT?.height,
      },
    }));
    /* eslint-disable-next-line */
  }, [currentKT]);

  const fetchReport = () => {
    dispatch(getReport({
      objectName: object.name,
      object_id: id,
      silos_name: currentSilos.name,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }));
  };

  const handleSetSettingsToSilos = async (e) => {
    e.preventDefault();

    const {
      warning, danger, culture, height, sensors_gap,
    } = settingsValues?.[currentKT?.addr];

    await http.put('/silos/settings/bulk', {
      object_id: id,
      silos_name: currentSilos.name,
      warning,
      danger,
      culture,
      sensors_gap,
      height,
    });

    setSettingsValues((prev) => ({
      ...prev[currentKT?.addr],
      [currentKT?.addr]: {
        ...prev[currentKT?.addr],
        saved: true,
      },
    }));

    objData.refetch();

    dispatch(getConfig({ object_id: id, silos_name: currentSilos.name }));

    dispatch(getTemperatures({
      object_id: id,
      silos_name: currentSilos.name,
    }));
    //
    // dispatch(setSettingsBulk({
    //   object_id: id,
    //   silos_name: currentSilos.name,
    //   warning,
    //   danger,
    // }));
  };

  const handleSetSettingsToObject = async (e) => {
    e.preventDefault();

    const {
      warning, danger, culture, height, sensors_gap,
    } = settingsValues?.[currentKT?.addr];

    await http.put('/silos/settings/bulk', {
      object_id: id,
      warning,
      danger,
      culture,
      sensors_gap,
      height,
    });

    setSettingsValues((prev) => ({
      ...prev[currentKT?.addr],
      [currentKT?.addr]: {
        ...prev[currentKT?.addr],
        saved: true,
      },
    }));

    dispatch(getConfig({ object_id: id, silos_name: currentSilos.name }));

    dispatch(getTemperatures({
      object_id: id,
      silos_name: currentSilos.name,
    }));
  };

  const handleFade = (ref) => {
    ref.current.style.opacity = '.5';
  };

  const handleFadeOut = (ref) => {
    ref.current.style.opacity = '1';
  };

  const tempCond = (log) => {
    if (log >= currentKT.danger) return 'bg-danger';
    if (log >= currentKT.warning) return 'bg-warning';
    return '';
  };

  const logs = temperatures?.sensors_data?.[currentKT?.addr || ''] === 'Нет ответа от термоподвески'
    ? []
    : temperatures?.sensors_data?.[currentKT?.addr || ''] ?? [];

  const updateSilos = async () => {
    const nulledValues = mapValues(settingsValues[currentKT?.addr], (val) => (val === '' || Number.isNaN(val) ? null : val));
    const nonNullishValues = omitBy(nulledValues, isNil);

    delete nonNullishValues.saved;

    await http.patch('/objects/silos/kt/config', {
      ...nonNullishValues,
    }, {
      params: {
        object_id: id,
        silos_name: currentSilos.name,
        KT_address: currentKT.addr,
      },
    });

    setSettingsValues((prev) => ({
      ...prev[currentKT?.addr],
      [currentKT?.addr]: {
        ...prev[currentKT?.addr],
        saved: true,
      },
    }));

    dispatch(getConfig({ object_id: id, silos_name: currentSilos.name }));

    dispatch(getTemperatures({
      object_id: id,
      silos_name: currentSilos.name,
    }));
  };

  const sortLogs = [...logs].sort((a, b) => a - b);

  return (
    <div className={`sidebarConfig-wrapper p-0 ${showConfigSidebar ? '' : 'sidebarConfig-wrapper--hide'}`}>
      <div className={`p-3 sidebarConfig ${showConfigSidebar ? '' : 'sidebarConfig--hide'}`}>
        <Button
          className="toggleSidebar"
          onClick={() => dispatch(setShowConfigSidebar(!showConfigSidebar))}
        >
          <MenuIcon width={26} height={26} />
        </Button>
        {currentKT.addr ? (
          <>
            <Row className="justify-content-center">
              <h3>
                {currentKT.addr}
                {' '}
                {settingsValues?.[currentKT?.addr]?.saved === false && (
                  <span className="bg-warning">(Не сохранено)</span>
                )}
              </h3>
            </Row>
            <Tabs
              defaultActiveKey="kt-info"
              className="mb-3"
            >
              <Tab eventKey="kt-info" title="Информация по подвеске">
                <Row className="flex-grow-1 mb-3">
                  <Col xs={4} className="sensorList-container">
                    <div className="sensorList d-flex flex-column">
                      {logs?.map((log, i) => (
                        <span
                          key={`temp_${currentKT.addr}_${i}`}
                          className={`${tempCond(log)} p-1 mb-1`}
                          style={{
                            width: '100%',
                            fontSize: 16,
                          }}
                        >
                          <span>
                            Д
                            {' '}
                            {i + 1}
                            :
                            {' '}
                          </span>
                          <span><span>{log?.toString() === '999' ? 'Н/Д' : log}</span></span>
                        </span>
                      ))}
                    </div>
                  </Col>
                  <Col xs={8}>
                    <div className="settings border rounded p-3">
                      <p className="font-weight-bold text-center">Ввод установок</p>
                      <Form>
                        <Form.Group as={Row} controlId="" className="m-0 mb-2 justify-content-between">
                          <Form.Label
                            column
                            xs={8}
                            className="px-0 text-right"
                          >
                            <span style={{ fontSize: 18 }}>Предупредительная</span>
                          </Form.Label>
                          <Col xs={3} className="p-0" style={{ flex: 1 }}>
                            <Form.Control
                              type="number"
                              min="0"
                              name="warningTemp"
                              className="bg-warning pe-1"
                              value={settingsValues?.[currentKT?.addr]?.warning ?? ''}
                              onChange={(e) => {
                                const { value } = e.target;
                                setSettingsValues((prev) => ({
                                  ...prev,
                                  [currentKT?.addr]: {
                                    ...prev[currentKT?.addr],
                                    saved: false,
                                    warning: value.length > 0 ? +e.target.value : NaN,
                                  },
                                }));
                              }}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="" className="m-0 mb-2 justify-content-between">
                          <Form.Label
                            column
                            xs={8}
                            className="px-0 text-right"
                          >
                            <span style={{ fontSize: 18 }}>Аварийная</span>
                          </Form.Label>
                          <Col xs={3} className="p-0" style={{ flex: 1 }}>
                            <Form.Control
                              type="number"
                              min="0"
                              name="dangerTemp"
                              className="bg-danger pe-1"
                              value={settingsValues?.[currentKT?.addr]?.danger ?? ''}
                              onChange={(e) => {
                                const { value } = e.target;
                                setSettingsValues((prev) => ({
                                  ...prev,
                                  [currentKT?.addr]: {
                                    ...prev[currentKT?.addr],
                                    saved: false,
                                    danger: value.length > 0 ? +e.target.value : NaN,
                                  },
                                }));
                              }}
                            />
                          </Col>
                          {+settingsValues.danger <= +settingsValues.warning && (
                            <Col>
                              <Form.Text className="text-danger">
                                Аварийная температура должна быть выше предупредительной
                              </Form.Text>
                            </Col>
                          )}
                        </Form.Group>
                      </Form>
                    </div>
                    <Form.Group controlId="culture">
                      <Form.Label><span style={{ fontSize: 18 }}>Культура</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="culture"
                        value={settingsValues?.[currentKT?.addr]?.culture || ''}
                        onChange={(e) => {
                          setSettingsValues((prev) => ({
                            ...prev,
                            [currentKT?.addr]: {
                              ...prev[currentKT?.addr],
                              saved: false,
                              culture: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="sensors_gap">
                      <Form.Label>
                        <span style={{ fontSize: 18 }}>Расстояние между датчиками</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="sensors_gap"
                        maxLength="5"
                        value={settingsValues?.[currentKT?.addr]?.sensors_gap || ''}
                        onChange={(e) => {
                          const { value } = e.target;
                          setSettingsValues((prev) => ({
                            ...prev,
                            [currentKT?.addr]: {
                              ...prev[currentKT?.addr],
                              saved: false,
                              sensors_gap: value.length > 0 ? +e.target.value : NaN,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="height">
                      <Form.Label>
                        <span style={{ fontSize: 18 }}>Высота термоподвески</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="height"
                        maxLength="5"
                        value={settingsValues?.[currentKT?.addr]?.height || ''}
                        onChange={(e) => {
                          const { value } = e.target;
                          setSettingsValues((prev) => ({
                            ...prev,
                            [currentKT?.addr]: {
                              ...prev[currentKT?.addr],
                              saved: false,
                              height: value.length > 0 ? +e.target.value : NaN,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="alias" ref={aliasRef}>
                      <Form.Label><span style={{ fontSize: 18 }}>Алиас</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="alias"
                        maxLength="5"
                        value={settingsValues?.[currentKT?.addr]?.alias || ''}
                        onChange={(e) => {
                          setSettingsValues((prev) => ({
                            ...prev,
                            [currentKT?.addr]: {
                              ...prev[currentKT?.addr],
                              saved: false,
                              alias: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                      <Dropdown as={ButtonGroup}>
                        <Button
                          disabled={
                            (loading?.length > 0 || +settingsValues?.[currentKT?.addr]?.danger)
                                <= +settingsValues?.[currentKT?.addr]?.warning
                          }
                          variant="primary"
                          onClick={updateSilos}
                        >
                          Применить
                        </Button>
                        <Dropdown.Toggle
                          split
                          variant="primary"
                          id="dropdown-split-basic"
                          // eslint-disable-next-line max-len
                          disabled={(loading?.length > 0 || +settingsValues?.[currentKT?.addr]?.danger)
                            <= +settingsValues?.[currentKT?.addr]?.warning}
                        />
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={handleSetSettingsToSilos}
                            onMouseOver={() => {
                              handleFade(aliasRef);
                            }}
                            onMouseOut={() => {
                              handleFadeOut(aliasRef);
                            }}
                          >
                            Применить к силосу
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={handleSetSettingsToObject}
                            onMouseOver={() => {
                              handleFade(aliasRef);
                            }}
                            onMouseOut={() => {
                              handleFadeOut(aliasRef);
                            }}
                          >
                            Применить к объекту
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="summary-info" title="Общая информация">
                <Row style={{ height: 'calc(100vh - 350px' }}>
                  <Col>
                    <div>
                      <div className="d-flex mb-1">
                        <div className="kt-info-item me-2">Макс. темп.</div>
                        <div>{sortLogs?.slice(-1) ? sortLogs?.slice(-1) : '-'}</div>
                      </div>
                      <div className="d-flex mb-1">
                        <div className="kt-info-item me-2">Мин. темп.</div>
                        <div>{sortLogs?.[0] ? sortLogs?.[0] : '-'}</div>
                      </div>
                      <div className="d-flex mb-1">
                        <div className="kt-info-item me-2">Объем</div>
                        {volumes?.[currentKT?.KT_address]?.volume !== -1 ? (
                          <span>{volumes?.[currentKT?.KT_address] ? `${volumes?.[currentKT?.KT_address]?.volume?.toFixed(1)} %` : '-' }</span>
                        ) : (
                          <span className="text-danger">ошибка</span>
                        )}
                      </div>
                      <div className="d-flex mb-1">
                        <div className="kt-info-item me-2">Культура</div>
                        <span>{currentKT?.culture ? currentKT?.culture : '-'}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab>

            </Tabs>
            <Row>
              <Col>
                <Button
                  as={Link}
                  variant="outline-primary"
                  className="w-100"
                  to={{
                    pathname: '/logs',
                    state: {
                      objectId: id,
                    },
                  }}
                >
                  Логи
                </Button>
              </Col>
              <Col className="p-0">
                <Button
                  as={Link}
                  to={{
                    pathname: '/statistics',
                    state: {
                      user_group_id: companyId,
                      objectId: id,
                      silosName: currentSilos.name,
                      kt: currentKT.addr,
                    },
                  }}
                  variant="outline-primary"
                  className="w-100"
                >
                  Статистика
                </Button>
              </Col>
              <Col>
                <Button
                  as={Link}
                  to={{
                    pathname: '/trends',
                    state: {
                      user_group_id: companyId,
                      objectId: id,
                      silosName: currentSilos.name,
                      kt: currentKT.addr,
                    },
                  }}
                  variant="outline-primary"
                  className="w-100"
                >
                  Тренды
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={fetchReport}
                  disabled={logsLoading.length > 0}
                >
                  {logsLoading.length > 0 ? 'Формирование...' : 'Сформировать отчёт'}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mt-1">
            <Col>
              <h4>Выберите термоподвеску</h4>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
