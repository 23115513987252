export const getKTColor = (log, kt, hex = false) => {
  const data = log?.sensors_data;
  const curKt = log?.options?.find((l) => l.KT_address === kt.addr);
  const max = Array.isArray(data?.[kt.addr])
    ? data?.[kt.addr]?.filter((v) => v !== 999)?.sort((a, b) => b - a)?.[0]
    : null;

  if (log?.status === 'no_kt_addresses') {
    return hex ? 'red' : 'no-sensors';
  }
  if (max >= curKt?.danger) {
    return hex ? '#FF5733' : 'bg-danger';
  }
  if (max >= curKt?.warning) {
    return hex ? '#FFF034' : 'bg-warning';
  }
  if (data?.[kt.addr]?.lenght > 0 && data?.[kt.addr]?.every((v) => v === 0)) {
    return hex ? '#FFFFFF' : 'bg-white';
  }
  // if (logs[kt.addr]?.every((v) => v === 'Н/Д')) {
  //   return 'bg-grey';
  // }
  if (data?.[kt.addr]?.lenght > 0 && data?.[kt.addr]?.some((v) => v === 999)) {
    return 'bg-white border-warning';
  }
  if (data?.[kt.addr] === 'Нет ответа от термоподвески') {
    return '#fff';
  }
  if (data?.[kt.addr]) {
    return hex ? '#00B900' : 'bg-good';
  }

  return '';
};
