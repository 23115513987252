/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-self-assign */
/* eslint-disable max-len */
import { Line, Text } from '@react-three/drei';
import { range } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import {
  useRef,
  useState,
  Suspense,
  useMemo,
  useEffect,
} from 'react';
import {
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { updateConfig, getConfig } from '../api/config';
import font from '../assets/font/pfdintextcomppro-regular-webfont.ttf';
import { getKTColor, convertFormDataToObject } from '../helpers';
import { useFullObjectsData } from '../queries';
import { setCurrentKT, setCurrentSilos } from '../store/reducers/config';
import { PlaneBox, SetupSilcorpus } from './Three';
import {
  Box,
  BOX_SIZE,
  BOX_SIZE_INNER,
  BOX_SIZE_WITH_GAP,
} from './Three/Box';
import { Loader } from './index';

export const SilosRect = ({
  name, config, formRef, view,
}) => {
  const dispatch = useDispatch();
  const {
    volumes, loading,
  } = useSelector(({ config }) => config);

  const { id } = useParams();
  const { search } = useLocation();
  const silosName = new URLSearchParams(search).get('silosName');

  const {
    objects,
  } = useSelector(({ object }) => object);

  const objectsIds = useMemo(() => objects
    && objects?.map((object) => object.object_id), [objects]);

  const objData = useFullObjectsData(objectsIds);

  const temperatures = objData?.data
    ?.filter((o) => o.object_id === Number(id)).find((o) => o.name === name);

  const [lastClick, setLastClick] = useState(0);

  const handleKTClick = (e, kt) => {
    // предотващать несколько срабатываний функции
    // потому что у квадратов сквозной ивент
    if (lastClick >= (Date.now() - 200)) { return; }

    setLastClick(Date.now());

    if (e?.target.value) {
      e.preventDefault();
      e.target.blur();
    }

    const options = temperatures?.options?.find((o) => o.KT_address === kt.addr);

    dispatch(setCurrentSilos({ name }));
    dispatch(setCurrentKT({
      addr: kt.addr === 'NaN' ? '' : kt.addr || '',
      alias: kt.alias === 'NaN' ? '' : kt.alias || '',
      ...options,
    }));
  };

  const handleKTDoubleClick = (e, kt) => {
    e.preventDefault();
    e.target.nextSibling.type = 'text';
    e.target.nextSibling.hidden = false;
    e.target.nextSibling.focus();
    e.target.nextSibling.defaultValue = (kt.addr === 'NaN' ? '' : kt.addr);
    e.target.hidden = true;
  };

  const handleKTBlur = (e, alias) => {
    e.preventDefault();
    e.target.hidden = true;
    e.target.previousSibling.hidden = false;
    if (!alias) {
      e.target.previousSibling.innerText = e.target.value;
    }
  };

  const [hiddenRows, setHiddenRows] = useState([]);

  useEffect(() => {
    if (config?.length > 3) {
      const hideRows = config?.reduce((acc, cur, index) => {
        if (![0, 1, 2].includes(index)) {
          acc = [...acc, index];
        }
        return acc;
      }, []);

      // eslint-disable-next-line no-unused-expressions
      hideRows.length > 0 && setHiddenRows(hideRows);
    }
  }, [config]);

  const controlRef = useRef();

  const handleSave = (e) => {
    const formDataEntries = Object.entries(convertFormDataToObject(e.target.form));
    const updatedConfig = Array(config.length).fill(null).map(() => []);

    formDataEntries.forEach(([inputName, value]) => {
      const [row, cell] = inputName.split('_').slice(-2);
      updatedConfig[row][cell] = value;
    });

    dispatch(updateConfig({
      object_id: id,
      silos_name: silosName,
      kt_addresses: updatedConfig,
    }));

    setTimeout(() => dispatch(getConfig({ object_id: id, silos_name: silosName })), [1000]);
  };

  if (loading.length > 0) {
    return <Loader />;
  }

  if (view === '3d') {
    return (
      <>
        <Button
          onClick={() => controlRef.current?.reset()}
          style={{
            position: 'absolute', bottom: 8, right: 8, zIndex: 10000, width: 'auto',
          }}
        >
          Вернуть камеру
        </Button>
        <SetupSilcorpus
          controlsProps={{
            ref: controlRef,
            minDistance: 10,
            maxDistance: 20,
            screenSpacePanning: false,
            enableRotate: false,
          }}
        >
          {config?.map((kt, i) => {
            const POS_Z = BOX_SIZE_WITH_GAP * -i;

            const isHidden = hiddenRows.includes(i);
            return (
              <>
                <mesh
                  onClick={() => setHiddenRows((prev) => (isHidden ? prev.filter((p) => p !== i) : [...prev, i]))}
                  position={[-5, -BOX_SIZE / 2, POS_Z]}
                  rotation={[Math.PI * 3 / 2, 0, 0]}
                >
                  <planeBufferGeometry
                    attach="geometry"
                    args={[1, 1]}
                  />
                  <meshPhongMaterial attach="material" color={isHidden ? '#DADADA' : 'green'} />
                </mesh>
                <Suspense fallback={null}>
                  <Text
                    font={font}
                    color="black"
                    scale={3}
                    anchorX="center"
                    anchorY="middle"
                    rotation={[Math.PI * 3 / 2, 0, Math.PI * 3 / 2]}
                    position={[-5, -BOX_SIZE / 2 + 0.01, POS_Z]}
                  >
                    {isHidden ? 'Показать' : 'Скрыть'}
                  </Text>
                </Suspense>
                {kt?.map((_k, j) => {
                  const data = temperatures?.sensors_data?.[_k.addr || ''] === 'Неверный формат записи'
                    ? []
                    : temperatures?.sensors_data?.[_k.addr || ''];
                  const idxs = range(-BOX_SIZE_INNER / 2, BOX_SIZE_INNER / 2, BOX_SIZE_INNER / data?.length).reverse();
                  const volume = volumes?.[_k.addr]?.volume ? BOX_SIZE_INNER * volumes?.[_k.addr]?.volume / 100 : 0;

                  const POS_X = BOX_SIZE_WITH_GAP * j;

                  return (
                    <>
                      <Suspense fallback={null}>
                        <Text
                          font={font}
                          visible={!isHidden}
                          color="black"
                          scale={5}
                          anchorX="center"
                          anchorY="middle"
                          rotation={[0, -45, 0]}
                          position={[POS_X, BOX_SIZE / 2 + 1, POS_Z]}
                        >
                          {_k.addr === 'NaN' ? '' : _k.addr}
                          {' '}
                          /
                          {' '}
                          {_k.alias === 'NaN' ? '' : _k.alias}
                        </Text>
                      </Suspense>
                      <PlaneBox
                        visible={!isHidden}
                        opacity={0.35}
                        position={[POS_X, (-BOX_SIZE_INNER / 2) + volume / 2, POS_Z]}
                        args={[BOX_SIZE_INNER, volume, BOX_SIZE_INNER]}
                      />
                      <Box
                        edgesProps={{ visible: isHidden, color: '#DADADA' }}
                        opacity={isHidden ? 0 : 0.35}
                        position={[POS_X, 0, POS_Z]}
                        onPointerUp={(e) => {
                          if (e.button === 0 && !isHidden) {
                            handleKTClick(null, _k);
                          }
                        }}
                      />
                      {data?.map((_d, di) => (
                        <Line
                          visible={!isHidden}
                          points={[[POS_X, idxs[di], POS_Z], [POS_X, idxs[di + 1] || -BOX_SIZE_INNER / 2, POS_Z]]}
                          color={getKTColor({ sensors_data: { [_k.addr]: [_d]}, options: temperatures?.options }, _k, true)}
                          lineWidth={4}
                        />
                      ))}
                    </>
                  );
                })}
              </>
            );
          })}
        </SetupSilcorpus>
      </>
    );
  }

  return (
    <div className={`silos-container s${name} rect`}>
      <form ref={formRef}>
        <table
          className="m-auto"
          style={{
            width: '100%',
            maxWidth: 800,
          }}
        >
          <tbody style={{
            borderWidth: 1,
          }}
          >
            {config?.length > 0 && config.map((conf, rowIdx) => (
              <tr key={`row_${name}_${rowIdx}`}>
                {conf?.map((v, cellIdx) => (
                  <td
                    style={{ borderWidth: 1 }}
                    className={getKTColor(temperatures, v)}
                    key={`cell_${name}_${rowIdx}_${cellIdx}`}
                  >
                    <div
                      style={{ height: '100%' }}
                      onDoubleClick={(e) => {
                        handleKTDoubleClick(e, v);
                      }}
                      onClick={(e) => handleKTClick(e, v)}
                      className="text-center"
                    >
                      {(v.alias === 'NaN' ? '' : v.alias) || (v.addr === 'NaN' ? '' : v.addr)}
                    </div>
                    <input
                      name={`kt_${rowIdx}_${cellIdx}`}
                      type="hidden"
                      onBlur={(e) => handleKTBlur(e, v.alias)}
                      defaultValue={v.addr}
                    />
                  </td>
                )) }
              </tr>
            )) }
          </tbody>
        </table>
        <Row>
          <Col className="d-flex justify-content-end mt-2">
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={isEmpty(temperatures)}
            >
              Сохранить
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};
