import { useMemo } from 'react';
import { Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from '../components';
import { useFullObjectsData } from '../queries';

export const SummaryObject = () => {
  const { objectId } = useParams();

  const {
    objects,
  } = useSelector(({ object }) => object);

  const objectsIds = useMemo(() => objects
    && objects?.map((object) => object.object_id), [objects]);

  const { data, isFetching } = useFullObjectsData(objectsIds);

  const objectInfo = data?.filter((object) => object.object_id === Number(objectId));

  const getSilosType = (type) => {
    if (type === 'rect') {
      return 'Силосный корпус';
    }
    if (type === 'round') {
      return 'Силос';
    }

    return 'Неизвестно';
  };

  const columns = [
    {
      dataField: 'silosId',
      text: '#',
    },
    {
      dataField: 'name',
      text: 'Название',
    },
    {
      dataField: 'type',
      text: 'Тип',
    },
    {
      dataField: 'culture',
      text: 'Культура',
    },
    {
      dataField: 'humidity',
      text: 'Влажность',
    },
    {
      dataField: 'mass',
      text: 'Масса (т)',
    },
    {
      dataField: 'level',
      text: 'Уровень',
    },
  ];

  const tableData = useMemo(() => (
    objectInfo ? (
      objectInfo.map((silos, index) => ({
        silosId: index + 1,
        name: silos.name,
        type: getSilosType(silos.type),
        culture: silos.culture?.[0] ? silos.culture?.[0] : '-',
        humidity: silos.humidity ? silos.humidity : '-',
        mass: silos.mass ? silos.mass : '-',
        level: silos.level < 0 ? 'ошибка' : `${silos.level}%`,
      }))
    ) : []
  ), [objectInfo]);

  const objectName = objects?.find((object) => object.object_id === Number(objectId))?.name;

  return (
    <Container>
      {isFetching && <Loader />}
      <Row className="justify-content-center"><h2 className="my-3 text-center">{objectName}</h2></Row>
      <BootstrapTable
        remote
        loading={isFetching}
        className="table-responsive"
        keyField="silos_id"
        data={tableData}
        columns={columns}
        noDataIndication="Записей нет"
        bordered={false}
        hover
      />
    </Container>
  );
};
