import { createSlice } from '@reduxjs/toolkit';

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: {
    showSidebar: true,
    showConfigSidebar: true,
  },
  reducers: {
    setShowSidebar: (state, { payload }) => {
      state.showSidebar = payload;
    },
    setShowConfigSidebar: (state, { payload }) => {
      state.showConfigSidebar = payload;
    },
  },
});

export const {
  setShowSidebar,
  setShowConfigSidebar,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
