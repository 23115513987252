/* eslint-disable max-len */
import { useEffect, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getObjectsWeather } from '../api/object';
import {
  WeatherInfoCard,
  WarningObjectGroupCard,
  SummaryObjectInfoCard,
  Loader,
} from '../components';
import { useFullObjectsData } from '../queries';

const getObjectStatuses = (objectInfo) => {
  let dangerStatusCount = 0;
  let warningStatusCount = 0;
  let okStatusCount = 0;

  objectInfo?.forEach((el) => {
    if (el.status === 'danger') {
      dangerStatusCount += 1;
    }

    if (el.status === 'warning') {
      warningStatusCount += 1;
    }

    if (el.status === 'ok') {
      okStatusCount += 1;
    }
  });

  return {
    danger: dangerStatusCount,
    warning: warningStatusCount,
    ok: okStatusCount,
  };
};

const getObjectTypes = (objectInfo) => {
  let round = 0;
  let rect = 0;
  let roundWithCenter = 0;

  objectInfo?.forEach((el) => {
    if (el.type === 'round') {
      round += 1;
    }

    if (el.type === 'rect') {
      rect += 1;
    }

    if (el.type === 'round_with_center') {
      roundWithCenter += 1;
    }
  });

  return {
    round,
    rect,
    roundWithCenter,
  };
};

export const ObjectPreview = () => {
  const dispatch = useDispatch();
  const { objectId } = useParams();

  const {
    objects, loading,
  } = useSelector(({ object }) => object);

  const objectsIds = useMemo(() => objects
    && objects?.map((object) => object.object_id), [objects]);

  const { data, isFetching } = useFullObjectsData(objectsIds);

  useEffect(() => {
    dispatch(getObjectsWeather({ object_id: objectId }));
  }, [objectId, dispatch]);

  const { objectsWeather } = useSelector(({ object }) => object);

  const objectInfo = useMemo(() => data?.filter((object) => object.object_id === Number(objectId)), [data, objectId]);

  return (
    <div className="w-100 d-flex justify-content-center align-items-center" style={{ minHeight: 'calc(100vh - 102px)' }}>

      {(isFetching || loading.lenght > 0) && <Loader />}

      <Container>
        <Row>
          <Col className="d-flex flex-wrap justify-content-center cursor-pointer">
            <WarningObjectGroupCard statuses={getObjectStatuses(objectInfo)} objectId={objectId} />

            <SummaryObjectInfoCard
              objectId={objectId}
              round={getObjectTypes(objectInfo)?.round + getObjectTypes(objectInfo)?.roundWithCenter}
              rect={getObjectTypes(objectInfo)?.rect}
            />

            <WeatherInfoCard
              temp={objectsWeather?.current?.temp_c}
              humidity={objectsWeather?.current?.humidity}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
