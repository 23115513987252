export const getRememberMe = () => localStorage.getItem('rememberMe') === 'true';
export const setRememberMe = async (state) => {
  localStorage.setItem('rememberMe', state);
  Promise.resolve();
};

export const getCurrentStorage = () => (getRememberMe() ? localStorage : sessionStorage);

export const getUserInfo = () => getCurrentStorage().getItem('user');
export const setUserInfo = (info) => getCurrentStorage().setItem('user', JSON.stringify(info));
export const removeUserInfo = () => getCurrentStorage().removeItem('user');

export const getAccessToken = () => getCurrentStorage().getItem('accessToken');
export const setAccessToken = (accessToken) => getCurrentStorage().setItem('accessToken', accessToken);
export const removeAccessToken = () => getCurrentStorage().removeItem('accessToken');

export const getRefreshToken = () => getCurrentStorage().getItem('refreshToken');
export const setRefreshToken = (refreshToken) => getCurrentStorage().setItem('refreshToken', refreshToken);
export const removeRefreshToken = () => getCurrentStorage().removeItem('refreshToken');
