import { useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  SilosCard,
  ObjectActionPanel,
  Loader,
  NotConfiguredObject,
} from '../components';
import { useFullObjectsData } from '../queries';

export const SilosesCards = () => {
  const { objectId } = useParams();
  const {
    objects,
  } = useSelector(({ object }) => object);

  const objectsIds = useMemo(() => objects
    && objects?.map((object) => object.object_id), [objects]);

  const { data, isFetching } = useFullObjectsData(objectsIds);

  // eslint-disable-next-line max-len
  const objectSiloses = useMemo(() => data?.filter((object) => object.object_id === Number(objectId)), [objectId, data]);

  return (
    (objectSiloses?.length === 0)
      ? (
        <Container className="d-flex flex-column justify-content-center " style={{ minHeight: 'calc(100vh - 72px)' }}>
          {(isFetching) && <Loader />}
          <NotConfiguredObject objectId={objectId} />
        </Container>
      )
      : (
        <div style={{ margin: '0 auto' }}>
          {(isFetching) && <Loader />}

          <ObjectActionPanel objectName="test" objectId={objectId} />
          <Container fluid className="d-flex flex-wrap justify-content-center mt-2" style={{ margin: '-12px' }}>
            {objectSiloses?.map((silos, index) => {
              const culture = silos.options?.find((option) => option.culture)?.culture;

              return (
                <SilosCard
                  key={index}
                  name={silos.name}
                  type={silos.type}
                  maxTemp={silos.max_temp}
                  level={silos.level}
                  humidity={silos.humidity}
                  status={silos.status}
                  culture={culture}
                  objectId={objectId}
                />
              );
            })}
          </Container>
        </div>
      )
  );
};
