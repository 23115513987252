import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export const WarningObjectGroupCard = ({ statuses, objectId }) => {
  const history = useHistory();

  // eslint-disable-next-line max-len
  const getNotConfiguredObject = () => statuses.danger === 0 && statuses.warning === 0 && statuses.ok === 0;

  return (
    <Card
      className="object-card pe-auto mb-4"
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/silosCards/${objectId}`);
      }}
    >
      <Card.Body className="d-flex flex-column justify-content-between">

        <Card.Title className="mb-2">{getNotConfiguredObject() ? 'Объект еще не настроен' : 'Силосы'}</Card.Title>
        {(getNotConfiguredObject())
          ? <div />
          : (
            <div className="lights">
              <div className={`d-flex align-items-center justify-content-center lights__circle ${statuses?.danger && 'lights__circle--danger'}`}>
                <span>{statuses?.danger}</span>
              </div>
              <div className={`d-flex align-items-center justify-content-center lights__circle ${statuses?.warning && 'lights__circle--warning'}`}>
                <span>{statuses?.warning}</span>
              </div>
              <div className={`d-flex align-items-center justify-content-center lights__circle ${statuses?.ok && 'lights__circle--primary'}`}>
                <span style={{ color: '#fff' }}>{statuses?.ok}</span>
              </div>
            </div>
          )}
      </Card.Body>
    </Card>
  );
};
