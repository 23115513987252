import { createSlice } from '@reduxjs/toolkit';

import {
  addCompany,
  getCompany,
  deleteCompany,
  getOneCompany,
  updateCompany,
} from '../../api/company';

export const companyEditSlice = createSlice({
  name: 'company',
  initialState: {
    companies: [],
    error: null,
    loading: [],
    current: {
      companyId: null,
      company: null,
    },
  },
  reducers: {
    setCurrentCompany: (state, { payload }) => {
      state.current.company = payload;
    },
    setCurrentCompanyId: (state, { payload }) => {
      state.current.companyId = payload;
    },
  },
  extraReducers: {
    [addCompany.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [addCompany.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.companies = state.companies.concat(payload);
    },
    [addCompany.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getCompany.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getCompany.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      // Сделать отдельный редюсер
      if (Array.isArray(payload)) {
        state.companies = payload;
      } else {
        state.current = payload;
      }
    },
    [getCompany.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [updateCompany.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [updateCompany.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.current.company = payload;
    },
    [updateCompany.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [deleteCompany.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [deleteCompany.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.companies = state.companies.filter((el) => el.user_group_id !== payload.user_group_id);
    },
    [deleteCompany.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getOneCompany.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getOneCompany.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.current.company = payload;
    },
    [getOneCompany.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

  },
});

export const { setCurrentCompany, setCurrentCompanyId } = companyEditSlice.actions;
export default companyEditSlice.reducer;
