import { Button } from 'react-bootstrap';

export const SilosViewSwitch = ({ onSwitch, view }) => (
  <div>
    <Button
      className="silos-view-switch"
      style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
      onClick={() => onSwitch('2d')}
      variant={view === '2d' ? 'primary' : 'outline-primary'}
    >
      2d
    </Button>
    <Button
      style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
      className="silos-view-switch"
      onClick={() => onSwitch('3d')}
      variant={view === '3d' ? 'primary' : 'outline-primary'}
    >
      3d
    </Button>
  </div>
);
