import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import { usePersistentState } from 'react-persistent-state';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { getTemperatures, getVolumes } from '../api/config';
import { ReactComponent as MenuIcon } from '../assets/menu.svg';
import { useFullObjectsData } from '../queries';
import { setShowConfigSidebar } from '../store/reducers/appSettings';
import {
  Legend,
  SilosRound,
  SilosRect,
  NotConfiguredObjectModal,
  SidebarObject,
  SilosViewSwitch,
  Loader,
} from './index';

export const ConfiguredObject = ({ silosEnum }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { search } = useLocation();
  const silosName = new URLSearchParams(search).get('silosName');

  const [show, setShow] = useState(false);

  const { showConfigSidebar } = useSelector(({ appSettings }) => appSettings);

  // useEffect(() => {
  //   dispatch(getTemperatures({
  //     object_id: id,
  //     silos_name: silosName,
  //   }));
  // }, [dispatch, id, silosName]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formRef = useRef();

  const [view, setView] = usePersistentState('2d', 'silos_view');

  useEffect(() => {
    if (silosName) {
      dispatch(getVolumes({ object_id: id, silos_name: silosName }));
    }
  }, [dispatch, id, silosName]);

  return (
    <Container fluid>
      <Row
        className="justify-content-between flex-nowrap"
        style={{
          height: 'calc(100vh - 72px)',
        }}
      >
        <Col className="w-50 p-0 d-flex flex-column">
          <div className="d-flex align-items-center ms-3 me-3 mt-3">
            <SilosViewSwitch onSwitch={(view) => setView(view)} view={view} />
            <div className="ms-auto me-5">
              <p className="mb-0">{silosName}</p>
            </div>
            <Button
              className="toggleSidebar toggleSidebar--right"
              onClick={() => dispatch(setShowConfigSidebar(!showConfigSidebar))}
            >
              <MenuIcon width={26} height={26} />
            </Button>
          </div>
          <Container fluid className="objectItemContainer position-relative mt-3 d-flex flex-column justify-content-center" style={{ flex: 1 }}>
            <Row style={{ height: '100%', alignItems: 'center' }}>
              {silosEnum.type === 'rect' ? (
                <SilosRect
                  view={view}
                  formRef={formRef}
                  name={silosEnum.name}
                  config={silosEnum.kt_addresses}
                />
              ) : (
                <SilosRound
                  view={view}
                  formRef={formRef}
                  name={silosEnum.name}
                  config={silosEnum.kt_addresses}
                  hasCenter={silosEnum.type === 'round_with_center'}
                />
              )}
            </Row>
          </Container>
        </Col>
        <SidebarObject />
      </Row>
      <Legend />
      <NotConfiguredObjectModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </Container>
  );
};
