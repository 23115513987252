import { useQuery } from '@tanstack/react-query';
import { getFullObjectsData } from '../api/object';

export const baseQueryKey = 'objects/silos/kt/config/list';

export const useFullObjectsData = (objectIds) => {
  const {
    data, isFetching, refetch,
  } = useQuery(
    [baseQueryKey, objectIds],
    () => getFullObjectsData(objectIds),
    {
      enabled: objectIds.length !== 0,
      staleTime: 60 * 1000 * 30,
    },
  );

  return {
    data, isFetching, refetch,
  };
};
