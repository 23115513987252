import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../config/axios';

export const addCompany = createAsyncThunk(
  'company/add',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.post('/userGroup', payload);
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getCompany = createAsyncThunk(
  'company/get',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/userGroup', {
        params: {
          user_group_id: payload,
        },
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getOneCompany = createAsyncThunk(
  'company/getOne',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/userGroup', {
        params: {
          user_group_id: payload,
        },
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const deleteCompany = createAsyncThunk(
  'company/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.delete('/userGroup', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const updateCompany = createAsyncThunk(
  'company/update',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.put('/userGroup', payload);
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);
