import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

export const BootstrapTableWithRemotePagination = ({
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  ...restProps
}) => (
  <BootstrapTable
    remote
    pagination={paginationFactory({
      page,
      sizePerPage,
      totalSize,
    })}
    onTableChange={onTableChange}
    {...restProps}
  />
);
