import isEmpty from 'lodash/isEmpty';
import {
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getStatusList, getLogs } from '../api/logs';
import { getObjects } from '../api/object';
import { LogsTable } from '../components';
import { getUserGroupId } from '../helpers/getUserGroupId';
import { setObjectLogs } from '../store/reducers/logs';

export const Logs = () => {
  const dispatch = useDispatch();
  const userGroupId = getUserGroupId();

  const [curObject, setCurObject] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [status, setStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);

  const { objects } = useSelector(({ object }) => object);
  const {
    statusList,
    showLoader,
    objectLogs,
  } = useSelector(({ logs }) => logs);

  const data = useMemo(() => ({
    object_id: curObject,
    limit,
    page: currentPage,
    from: dateFrom,
    to: dateTo,
    status_code: status,
  }), [curObject, limit, currentPage, dateFrom, dateTo, status]);

  useEffect(() => {
    if (userGroupId) {
      dispatch(getObjects({ user_group_id: userGroupId }));
    }
  }, [dispatch, userGroupId]);

  useEffect(() => {
    if (curObject) {
      dispatch(getStatusList({ object_id: curObject }));
    }
  }, [dispatch, curObject]);

  useEffect(() => {
    if (data.object_id) {
      dispatch(getLogs(data));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.page]);

  useEffect(() => {
    dispatch(setObjectLogs([]));
  }, [dispatch]);

  const handleSubmit = () => {
    dispatch(getLogs(data));
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <h2 className="my-3">Логи</h2>
      </Row>
      <Form>
        <Row>
          <Col md={6}>
            <Row>
              <Col md={12} xl={6} className="mb-3">
                <Form.Group controlId="formGridState">
                  <Form.Control
                    as="select"
                    defaultValue="Выберите объект"
                    value={curObject}
                    onChange={(e) => setCurObject(e.target.value)}
                  >
                    <option disabled>Выберите объект</option>
                    {objects.map((el) => (
                      <option key={el.object_id + el.name} value={el.object_id}>{el.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={12} xl={6} className="mb-3">
                <Form.Group controlId="formGridState">
                  <Form.Control
                    as="select"
                    defaultValue="Выберите статус"
                    value={status}
                    disabled={isEmpty(statusList)}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option disabled>Выберите статус</option>
                    {statusList.map((el) => (
                      <option key={el.code} value={el.code}>{el.status}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className="align-items-start">
              <Col md={12} xl={5} className="mb-3">
                <Form.Group
                  className="d-flex align-items-center pe-0"
                  controlId="dateFrom"
                >
                  <Form.Label className="mb-0 me-1">c</Form.Label>
                  <Form.Control
                    type="date"
                    disabled={!status}
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={9} xl={5} className="mb-3 mr-3">
                <Form.Group
                  className="d-flex align-items-center"
                  controlId="dateTo"
                >
                  <Form.Label className="mb-0 me-1">по</Form.Label>
                  <Form.Control
                    type="date"
                    disabled={!dateFrom}
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col sm={3} xl={2} className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={!curObject}
                >
                  Показать
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <LogsTable
        objectLogs={objectLogs.rows}
        totalPages={objectLogs.count}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onTableChange={handleSubmit}
        loading={showLoader}
      />
    </Container>
  );
};
