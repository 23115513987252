import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../config/axios';

export const attachUser = createAsyncThunk(
  'usersObject/post',
  async (payload, { rejectWithValue }) => {
    try {
      const { data: objectUser } = await http.post('/usersObject',
        { object_id: payload.objectId, user_id: payload.userId });
      return objectUser;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const detachUser = createAsyncThunk(
  'usersObject/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const { data: objectUser } = await http.delete('/usersObject', {
        params: { object_id: payload.objectId, user_id: payload.userId },
      });
      return objectUser;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);
