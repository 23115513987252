import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { getConfig } from '../api/config';
import {
  ConfiguredObject,
  NotConfiguredObject,
  Loader,
} from '../components';
import { resetConfig } from '../store/reducers/config';

export const Silos = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { search } = useLocation();
  const silosName = new URLSearchParams(search).get('silosName');

  const { silosEnum, loading } = useSelector(({ config }) => config);

  useEffect(() => {
    dispatch(getConfig({ object_id: id, silos_name: silosName }));

    return () => {
      dispatch(resetConfig());
    };
  }, [dispatch, id, silosName]);

  return (
    (!Object.keys(silosEnum).length)
      ? (
        <Container className="d-flex flex-column justify-content-center " style={{ minHeight: 'calc(100vh - 72px)' }}>
          {loading?.length > 0 && <Loader />}
          <NotConfiguredObject objectId={id} />
        </Container>
      )
      : <ConfiguredObject silosEnum={silosEnum} />
  );
};
