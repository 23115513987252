import {
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { exportConfig, importConfig } from '../api/config';
import { getObjectsSiloses } from '../api/object';
import http from '../config/axios';
import { useFullObjectsData } from '../queries';

import { NotConfiguredObjectModal } from './NotConfiguredObjectModal';

export const ObjectActionPanel = ({ objectName, objectId }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [show, setShow] = useState(false);

  const handleDownload = () => {
    dispatch(exportConfig({
      object_id: objectId,
      objectName,
    }));
  };

  const {
    objects,
  } = useSelector(({ object }) => object);

  const objectsIds = useMemo(() => objects
  && objects?.map((object) => object.object_id), [objects]);

  const objData = useFullObjectsData(objectsIds);

  const handleRefUpload = () => {
    fileInputRef.current?.click();
  };
  const handleUpload = async (e) => {
    const formData = new FormData();
    formData.append('config', e.target.files[0], `${objectId}.gml`);
    await http.post('/silos/import', formData);

    objData.refetch();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="d-flex flex-column flex-sm-row ">
      <div>
        <Button
          className="mt-3 ms-2"
          variant="primary"
          onClick={handleShow}
        >
          Добавить силос
        </Button>
      </div>
      <div>
        <input
          ref={fileInputRef}
          type="file"
          className="d-none"
          onChange={handleUpload}
          accept=".gml"
        />
        <Button
          variant="outline-primary"
          onClick={handleRefUpload}
          // disabled={data.user_type_id === 2}
          className="mt-3 ms-2"
        >
          Импортировать
        </Button>
        <Button
          variant="outline-primary"
          onClick={handleDownload}
          className="mt-3 ms-2"
        >
          Экспортировать
        </Button>

        <NotConfiguredObjectModal
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      </div>
    </div>
  );
};
