import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Vector3 } from 'three';

export const SetupSilcorpus = ({
  children,
  cameraFov = 75,
  cameraPosition = new Vector3(-5, 5, 5),
  controlRef,
  controlsProps = {},
  ...restProps
}) => (
  <Canvas
    camera={{ position: cameraPosition, fov: cameraFov }}
    {...restProps}
  >
    {children}
    <ambientLight intensity={0.9} />
    <OrbitControls
      {...controlsProps}
    />
  </Canvas>
);
