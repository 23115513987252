import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUsersCompany } from '../api/users';
import { attachUser, detachUser } from '../api/usersObject';
import { ReactComponent as CrossIcon } from '../assets/Cross.svg';
import { ReactComponent as GearIcon } from '../assets/Gear.svg';
import { getUserGroupId } from '../helpers/getUserGroupId';
import { setCurrentUser } from '../store/reducers/user';
import { UserModal, DeleteModal } from './index';

export const UsersTable = ({ select, ...props }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const userGroupId = getUserGroupId();

  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [users, setUsers] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [create, setCreate] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = (id, name) => {
    setUserId(id);
    setUserName(name);
    setShowDeleteModal(true);
  };

  const {
    companyUsers,
    objectUser,
    showLoader,
  } = useSelector(({ user }) => user);
  const selectedUser = useSelector(({ user }) => user.objectUser.map((v) => +v.user_id));

  useEffect(() => {
    if (id) {
      dispatch(getUsersCompany({ user_group_id: userGroupId }));
    }
  }, [dispatch, id, userGroupId]);

  useEffect(() => {
    setUsers(companyUsers);
  }, [props.objectUsers, objectUser, companyUsers]);

  const editUser = (id) => {
    setUserId(id);
    handleShow();
  };

  const addUser = () => {
    setCreate(true);
    dispatch(setCurrentUser(null));
    handleShow();
  };

  const columns = [
    {
      dataField: 'user_id',
      text: '#',
      formatter: (_, row, idx) => idx + 1,
    },
    {
      dataField: 'last_name',
      text: 'ФИО',
      formatter: (_, row) => `${row.last_name} ${row.first_name}`,
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'mobile_phone',
      text: 'Моб. тел.',
    },
    {
      dataField: 'work_phone',
      text: 'Раб. тел.',
    },
    {
      dataField: 'user_type_id',
      text: 'Роль',
      formatter: (cell) => {
        if (cell === 2) {
          return 'Клиент';
        }
        if (cell === 1) {
          return 'Админ';
        }

        return '';
      },
    },
    {
      dataField: 'actions',
      text: '',
      formatter: (_, row) => (
        <div className="d-flex">
          <GearIcon
            className="me-2"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              editUser(row.user_id);
            }}
          />
          <CrossIcon
            style={{ cursor: 'pointer' }}
            onClick={() => handleShowDeleteModal(row.user_id, row.last_name)}
            className="d-flex align-items-center"
          />
        </div>
      ),
    },
  ];

  const selectRow = props.objectUsers ? {
    mode: 'checkbox',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        dispatch(attachUser({
          objectId: id,
          userId: [row.user_id],
        }));
      } else {
        dispatch(detachUser({
          objectId: id,
          userId: row.user_id,
        }));
      }
    },
    hideSelectAll: true,
    selected: selectedUser,
  } : undefined;

  return (
    <>
      <div className="mt-3 overflow-auto">
        <BootstrapTable
          keyField="user_id"
          data={users || []}
          columns={columns}
          noDataIndication="Записей нет"
          bordered={false}
          hover
          selectRow={selectRow}
          loading={showLoader}
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: (base) => ({ ...base, background: 'rgba(206, 206, 206, 0.5)' }),
            },
          })}
        />
      </div>
      <div className="d-flex justify-content-end">
        { props.companyUsers && (
          <Button
            variant="primary"
            onClick={addUser}
          >
            Добавить
          </Button>
        )}
      </div>
      <UserModal
        companyId={userGroupId}
        userId={userId}
        show={show}
        create={create}
        objectUsers={props.objectUsers}
        handleClose={handleClose}
        handleShow={handleShow}
      />
      <DeleteModal
        show={showDeleteModal}
        title={`пользователя ${userName}`}
        id={userId}
        handleClose={handleCloseDeleteModal}
        handleShow={handleShowDeleteModal}
        item="user"
      />
    </>
  );
};
