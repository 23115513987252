import { useState } from 'react';
import {
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { ReactComponent as Cross } from '../assets/cross.svg';
import { ReactComponent as Info } from '../assets/info.svg';

export const Legend = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        className={`legend border border-primary p-3 fixed-bottom border-bottom-0 border-left-0 ${show ? 'legend--show' : 'legend--hide'}`}
      >
        <Row>
          <Col xs={12} md={6} className="d-flex align-items-center my-1">
            <span className="border bg-grey border-dark rounded-circle me-2 p-3" style={{ width: '30px', height: '30px' }}> </span>
            <span>Опрос термоподвески выключен</span>
          </Col>
          <Col xs={12} md={6} className="d-flex align-items-center my-1">
            <span className="border bg-greyL1 border-dark rounded-circle me-2 p-3" style={{ width: '30px', height: '30px' }}> </span>
            <span>Все датчики термоподвески показывают 0</span>
          </Col>
          <Col xs={12} md={6} className="d-flex align-items-center my-1">
            <span className="border bg-good border-dark rounded-circle me-2 p-3" style={{ width: '30px', height: '30px' }}> </span>
            <span>Опрос термоподвески включен</span>
          </Col>
          <Col xs={12} md={6} className="d-flex align-items-center my-1">
            <span className="border bg-greyL2 border-warning rounded-circle me-2 p-3" style={{ width: '30px', height: '30px' }}> </span>
            <span>Не опрашивается термоподвеска или один из датчиков</span>
          </Col>
          <Col xs={12} md={6} className="d-flex align-items-center my-1">
            <span className="border bg-warning border-dark rounded-circle me-2 p-3" style={{ width: '30px', height: '30px' }}> </span>
            <span>Превышено предупредительное значение температуры</span>
          </Col>
          <Col xs={12} md={6} className="d-flex align-items-center my-1">
            <span className="border bg-danger border-dark rounded-circle me-2 p-3" style={{ width: '30px', height: '30px' }}> </span>
            <span>Превышено аварийное значение температуры</span>
          </Col>
        </Row>
        <Button
          className="legend__hide-icon"
          onClick={() => setShow(!show)}
          variant="secondary"
        >
          <Cross width={15} height={15} />
        </Button>
      </div>
      <Button
        className="legend_icon"
        onClick={() => setShow(!show)}
        variant="secondary"
      >
        <Info width={25} height={25} />
      </Button>
    </>
  );
};
