class JwtHelper {
  constructor() {
    this.tokens = JSON.parse(localStorage.getItem('tokens')) || {};
    this.decodedAccessToken = this.tokens.accessToken && JSON.parse(atob(this.tokens.accessToken.split('.')[1]));
    this.decodedRefreshToken = this.tokens.refreshToken && JSON.parse(atob(this.tokens.refreshToken.split('.')[1]));
    this.isAuthenticated = !!this.tokens.accessToken;
  }

  decodeToken() {
    this.decodedAccessToken = JSON.parse(atob(this.tokens.accessToken.split('.')[1]));
    this.decodedRefreshToken = JSON.parse(atob(this.tokens.refreshToken.split('.')[1]));
  }

  getUserData() {
    if (this.decodedAccessToken) {
      delete this.decodedAccessToken.iat;
      delete this.decodedAccessToken.exp;
    }

    return this.decodedAccessToken;
  }

  getRefreshTokenExp() {
    return this.decodedRefreshToken && this.decodedRefreshToken.exp * 1000;
  }

  getAccessToken() {
    return this.tokens.accessToken;
  }

  getRefreshToken() {
    return this.tokens.refreshToken;
  }

  setTokens(tokens) {
    localStorage.setItem('tokens', JSON.stringify(tokens));
    this.tokens = tokens;
    this.isAuthenticated = true;
    this.decodeToken();
  }

  removeTokens() {
    localStorage.removeItem('tokens');
    this.tokens = {};
    this.isAuthenticated = false;
  }
}

export const jwt = new JwtHelper();
