import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../config/axios';
import { downloadFile } from '../helpers';

export const getConfig = createAsyncThunk(
  'config/get',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/objects/silos/kt', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const addConfig = createAsyncThunk(
  'config/add',
  async (payload, { rejectWithValue }) => {
    try {
      Object.keys(payload).forEach((el) => {
        if (el !== 'silos_name' && el !== 'c') {
          payload[el] = Number(payload[el]);
        }
      });
      const { data } = await http.post('/silos', payload);
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const updateConfig = createAsyncThunk(
  'config/update',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.put('/objects/silos/kt', { KT_addresses: payload.kt_addresses }, { params: { object_id: payload.object_id, silos_name: payload.silos_name }});
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const setSettings = createAsyncThunk(
  'config/setSettings',
  async ({ silosName, ...payload }, { rejectWithValue }) => {
    try {
      const { data } = await http.put('/silos/settings', payload);
      return { data, ...payload };
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const setSettingsBulk = createAsyncThunk(
  'config/setSettingsBulk',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.put('/silos/settings/bulk', payload);
      return { data, ...payload };
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const importConfig = createAsyncThunk(
  'config/import',
  async ({ file, objectId }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('config', file, `${objectId}.gml`);
      const { data } = await http.post('/silos/import', formData);
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const exportConfig = createAsyncThunk(
  'config/export',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/silos/export', {
        params: payload,
      });
      if (data) {
        downloadFile(payload.objectName, null, data);
      }
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getTemperatures = createAsyncThunk(
  'config/getTemp',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/objects/silos/kt/config', {
        params: {
          ...payload,
        },
      });

      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getVolumes = createAsyncThunk(
  '/objects/silos/kt/volume',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/objects/silos/kt/volume', {
        params: {
          ...payload,
        },
      });

      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);
