import axios from 'axios';
import {
  getAccessToken,
  getCurrentStorage,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../helpers';
import { BACKEND_URL } from './const';

const http = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    const rToken = getRefreshToken();

    if (rToken) {
      const base64Payload = rToken.split('.')[1];
      const payload = Buffer.from(base64Payload, 'base64');
      const { exp } = JSON.parse(payload.toString());

      if (Date.now() / 1000 > exp) {
        getCurrentStorage().clear();
        window.location.reload();

        return config;
      }
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

http.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;

  if (
    error.response.status === 401
    && error.response.data === 'Authentication Error'
    && !originalRequest._retry
  ) {
    originalRequest._retry = true;

    const { data } = await http.post('/refreshToken', {
      refreshToken: getRefreshToken(),
    });

    setAccessToken(data.accessToken);
    setRefreshToken(data.refreshToken);

    return http(originalRequest);
  }
  return Promise.reject(error);
});

export default http;
