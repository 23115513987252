import { combineReducers } from 'redux';
import appSettingsReducer from './appSettings';

import companyReducer from './company';
import configReduser from './config';
import logsReducer from './logs';
import objectReducer from './object';
import userReducer from './user';

const reducer = combineReducers({
  user: userReducer,
  company: companyReducer,
  object: objectReducer,
  logs: logsReducer,
  config: configReduser,
  appSettings: appSettingsReducer,
});

export default reducer;
