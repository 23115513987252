import { ReactComponent as Silcorpus } from '../assets/silcorpus.svg';
import { ReactComponent as SilcorpusError } from '../assets/silcorpusError.svg';
import { ReactComponent as SilcorpusSuccess } from '../assets/silcorpusSuccess.svg';
import { ReactComponent as SilcorpusWarning } from '../assets/silcorpusWarning.svg';
import { ReactComponent as Silos } from '../assets/silos.svg';
import { ReactComponent as SilosError } from '../assets/silosError.svg';
import { ReactComponent as SilosSuccess } from '../assets/silosSuccess.svg';
import { ReactComponent as SilosWarning } from '../assets/silosWarning.svg';

export const getSilosTypeIcon = (type, status) => {
  if (['round', 'round_with_center'].includes(type) && status === 'ok') {
    return <SilosSuccess />;
  }

  if (['round', 'round_with_center'].includes(type) && status === 'danger') {
    return <SilosError />;
  }

  if (['round', 'round_with_center'].includes(type) && status === 'warning') {
    return <SilosWarning />;
  }

  if (type === 'rect' && status === 'ok') {
    return <SilcorpusSuccess />;
  }

  if (type === 'rect' && status === 'danger') {
    return <SilcorpusError />;
  }

  if (type === 'rect' && status === 'warning') {
    return <SilcorpusWarning />;
  }

  if (type === 'rect') {
    return <Silcorpus />;
  }

  if (['round', 'round_with_center'].includes(type)) {
    return <Silos />;
  }

  return null;
};
