import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../config/axios';
import { downloadFile } from '../helpers';

export const getLogs = createAsyncThunk(
  'logs/get',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/logs', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getLogsKT = createAsyncThunk(
  'logsKT/get',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/logs/kt', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getStatusList = createAsyncThunk(
  'status/get',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/logs/list', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getReport = createAsyncThunk(
  'logs/report',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/logs/report', {
        params: payload,
      });
      if (data) {
        downloadFile(payload.objectName, payload.silos_name, data);
      }
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);
