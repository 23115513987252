import { Redirect, Route } from 'react-router-dom';
import {
  Login,
  Objects,
  Company,
  AddObjectProperties,
  Silos,
  AddCompany,
  EditObjectProperties,
  Logs,
  Statistics,
  Trends,
  ObjectPreview,
  SilosesCards,
  SummaryObject,
} from '../pages/index';

export const PrivateRoute = ({ user, ...rest }) => {
  if (user.data) return <Route {...rest} />;
  return <Redirect to="/" />;
};

export const routes = [
  {
    exact: true,
    path: '/',
    component: Login,
  },
  {
    auth: true,
    path: '/objects',
    component: Objects,
  },
  {
    auth: true,
    path: '/company/:id',
    component: Company,
  },
  {
    auth: true,
    exact: true,
    path: '/company',
    component: AddCompany,
  },
  {
    auth: true,
    path: '/objectProperties/:id',
    component: EditObjectProperties,
  },
  {
    auth: true,
    exact: true,
    path: '/objectProperties',
    component: AddObjectProperties,
  },
  {
    auth: true,
    path: '/object/:id',
    component: Silos,
  },
  {
    auth: true,
    path: '/logs/:id',
    component: Logs,
  },
  {
    auth: true,
    path: '/statistics/:id',
    component: Statistics,
  },

  {
    auth: true,
    exact: true,
    path: '/logs',
    component: Logs,
  },
  {
    auth: true,
    path: '/statistics',
    component: Statistics,
  },
  {
    auth: true,
    path: '/trends',
    component: Trends,
  },
  {
    auth: true,
    path: '/objectPreview/:objectId',
    component: ObjectPreview,
  },
  {
    auth: true,
    path: '/silosCards/:objectId',
    component: SilosesCards,
  },

  {
    auth: true,
    path: '/summaryObject/:objectId',
    component: SummaryObject,
  },
];
