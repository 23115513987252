import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import http from '../config/axios';

export const addObject = createAsyncThunk(
  'object/add',
  async (payload, { rejectWithValue }) => {
    try {
      const result = {};
      const { data: object } = await http.post('/objects', payload.object);
      result.object = object;
      return result;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getObjects = createAsyncThunk(
  'object/getBulk',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/objects', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getObject = createAsyncThunk(
  'object/get',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/object', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const updateObject = createAsyncThunk(
  'object/put',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.put('/objects', payload.data);
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const deleteObject = createAsyncThunk(
  'object/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.delete('/objects', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getObjectsStatus = createAsyncThunk(
  'object/status',

  async (payload, { rejectWithValue }) => {
    const searchParams = new URLSearchParams();
    payload.object_ids.forEach((id) => searchParams.append('object_ids', id));

    try {
      const { data } = await http.get(`/objects/statuses?${searchParams.toString()}`);
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getObjectsPreview = createAsyncThunk(
  'objects/preview',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/objects/preview', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getObjectsWeather = createAsyncThunk(
  'objects/weather',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/objects/weather', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getObjectsSiloses = createAsyncThunk(
  'objects/siloses',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/objects/siloses', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getObjectsSilosKTconfig = createAsyncThunk(
  'objects/silos/kt/config',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/objects/silos/kt/config', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getObjectsSilosKT = createAsyncThunk(
  'objects/silos/kt',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/objects/silos/kt', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getFullObjectsData = async (objectsIds) => {
  const { data } = await http.get('objects/silos/kt/config/list', {
    params: {
      objects_ids: objectsIds,
    },

    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};
