import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import rootReducer from './reducers';

// eslint-disable-next-line func-names
export default function (preloadedState) {
  const middleware = [...getDefaultMiddleware()];

  const store = configureStore({
    reducer: rootReducer,
    middleware,
    preloadedState,
  });

  return store;
}
