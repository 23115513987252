import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Form,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addCompany, updateCompany } from '../api/company';
import { convertFormDataToObject } from '../helpers';
import { UsersTable, Loader } from './index';

export const CompanyProperies = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [key, setKey] = useState('properties');
  const [formData, setFormData] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [done, setDone] = useState(false);

  const {
    companies,
    current,
    error,
    loading,
  } = useSelector(({ company }) => company);

  const handleFormChange = (e) => {
    setFormData(convertFormDataToObject(e.target.form));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!id) {
      dispatch(addCompany(formData));
      setDone(true);
    } else {
      dispatch(updateCompany({
        name: companyName,
        object_list: current.company.object_list,
        user_group_id: current.company.user_group_id,
      }));
    }
  };

  useEffect(() => {
    if (done) {
      history.push('/objects');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    if (id) {
      setCompanyName(current.company?.name);
    }
  }, [current.company?.name, id]);

  return (
    <Container>
      {loading.length > 0 && <Loader />}

      <Row className="justify-content-center">
        <h2 className="my-3">{!id ? 'Новая компания' : current.company?.name}</h2>
      </Row>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab
          eventKey="properties"
          title="Свойства"
          className="pb-1"
        >
          <Form
            onSubmit={handleSubmit}
            onChange={handleFormChange}
          >
            <fieldset disabled={loading.lenght > 0}>
              <Form.Group controlId="name">
                <Form.Label>Название</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={companyName}
                  required
                  onChange={(e) => setCompanyName(e.target.value)}
                  isInvalid={error}
                />
                <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button
                  disabled={!companyName || loading.lenght > 0}
                  variant="primary"
                  type="submit"
                  className="mt-2"
                >
                  Сохранить
                </Button>
              </div>
            </fieldset>
          </Form>
        </Tab>
        <Tab
          eventKey="users"
          title="Пользователи"
          className="pb-1"
          disabled={!id}
        >
          <UsersTable companyUsers />
        </Tab>
      </Tabs>
    </Container>
  );
};
