import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getObjects, getObjectsStatus } from '../api/object';
import { ReactComponent as CrossIcon } from '../assets/Cross.svg';
import { ReactComponent as GearIcon } from '../assets/Gear.svg';
import { setCurrentUserId } from '../store/reducers/user';
import { UserModal, DeleteModal } from './index';

export const NavItemAdmin = ({
  id,
  isActive,
  groupId,
  name,
}) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [creacte, setCreate] = useState(false);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = (id, name) => {
    setCreate(true);
    setUserId(id);
    setUserName(name);
    setShowDeleteModal(true);
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const editUser = (id) => {
    setUserId(id);
    handleShow();
  };

  const fetchObjects = (id) => {
    dispatch(setCurrentUserId(id));
    dispatch(getObjects({ user_id: id }));
    dispatch(getObjectsStatus({ user_group_id: id }));
  };

  return (
    <Nav.Item
      key={`key-${id}`}
      className="d-flex justify-content-between align-items-center"
    >
      <Nav.Link
        className={isActive ? 'active' : ''}
        onClick={() => fetchObjects(id)}
      >
        {name}
      </Nav.Link>
      { !groupId ? (
        <>
          <div className="d-flex">
            <GearIcon
              className="me-2"
              onClick={() => editUser(id)}
            />
            <CrossIcon
              onClick={() => handleShowDeleteModal(id, name)}
              style={{ cursor: 'pointer' }}
              className="d-flex align-items-center"
            />
          </div>
          <UserModal
            userId={userId}
            show={showModal}
            creacte={creacte}
            handleClose={handleClose}
            handleShow={handleShow}
          />
          <DeleteModal
            show={showDeleteModal}
            title={`пользователя ${userName}`}
            id={userId}
            handleClose={handleCloseDeleteModal}
            handleShow={handleShowDeleteModal}
            item="user"
          />
        </>
      ) : null }
    </Nav.Item>
  );
};
