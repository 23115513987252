import { useEffect } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getObjects, getObjectsStatus } from '../api/object';
import { ReactComponent as MenuIcon } from '../assets/menu.svg';
import {
  ObjectsTable,
  SidebarSuperUser,
  SidebarAdmin,
  Loader,
} from '../components';
import { getUserGroupId } from '../helpers';
import { setShowSidebar } from '../store/reducers/appSettings';
import { setCurrentCompanyId } from '../store/reducers/company';

export const Objects = () => {
  const dispatch = useDispatch();
  const userGroupId = getUserGroupId();

  const { data, loadingUser } = useSelector(({ user }) => user);
  const { current, loadingCompany } = useSelector(({ company }) => company);
  const { showSidebar } = useSelector(({ appSettings }) => appSettings);

  useEffect(() => {
    dispatch(setCurrentCompanyId(null));
  }, [dispatch]);

  useEffect(() => {
    if (data.user_type_id === 2) {
      dispatch(getObjects({ user_id: data.user_id }));
      dispatch(getObjectsStatus({ user_group_id: data.user_groud_id }));
    }
  }, [dispatch, data.user_type_id, data.user_id, data.user_groud_id]);

  return (
    (data.user_type_id === 2)
      ? (
        <Container>
          <Row>
            <Col>
              <ObjectsTable usersObject userGroupId={data.user_groud_id} />
            </Col>
          </Row>
        </Container>
      )
      : (
        <Container fluid>

          {(loadingCompany?.length > 0 || loadingUser?.length > 0) && <Loader />}

          <Row className="flex-nowrap">
            {(data.user_type_id === 0) && (
              <SidebarSuperUser />
            )}
            {(data.user_type_id === 1) && (
              <SidebarAdmin />
            )}
            <Col>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <Button
                    className="toggleSidebar toggleSidebar--left"
                    onClick={() => dispatch(setShowSidebar(!showSidebar))}
                  >
                    <MenuIcon width={26} height={26} />
                  </Button>
                  <Link to={{
                    pathname: '/objectProperties',
                    state: {
                      isNew: true,
                      userGroupId: data.user_type_id === 0
                        ? current.companyId
                        : data.user_group_id,
                    },
                  }}
                  >
                    <Button disabled={!userGroupId && data.user_type_id === 0}>
                      Добавить объект
                    </Button>
                  </Link>
                </Col>
              </Row>
              <ObjectsTable
                userGroupId={data.user_type_id === 1 ? data.user_groud_id : userGroupId}
              />
            </Col>
          </Row>
        </Container>
      )
  );
};
