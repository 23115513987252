import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FormLogin, Loader } from '../components';

export const Login = () => {
  const user = useSelector(({ user }) => user);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col
          xs={12}
          md={6}
          style={{
            marginTop: 'calc(50vh - 72px)',
            transform: 'translate(0, -50%)',
          }}
        >
          { !user.checking
            ? <FormLogin />
            : <Loader />}
        </Col>
      </Row>
    </Container>
  );
};
