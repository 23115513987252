import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Row,
  Col,
  Container,
  Button,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getObject, getObjectsSiloses } from '../api/object';
import http from '../config/axios';
import { useFullObjectsData } from '../queries';
import { FormConfig } from './FormConfig';
import { Loader } from './Loader';
import { NotConfiguredObjectModal } from './NotConfiguredObjectModal';

export const NotConfiguredObject = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const fileInputRef = useRef(null);

  const {
    object, objects, loadingObject,
  } = useSelector(({ object }) => object);
  const { data, loadingUser } = useSelector(({ user }) => user);

  useEffect(() => {
    dispatch(getObject({ object_id: props.objectId }));
  }, [dispatch, props.objectId]);

  const handleClose = (e) => {
    e.stopPropagation();
    setShow(false);
  };
  const handleShow = (e) => {
    e.stopPropagation();
    setShow(true);
  };
  const handleRefUpload = () => {
    fileInputRef.current?.click();
  };

  const objectsIds = useMemo(() => objects
    && objects?.map((object) => object.object_id), [objects]);

  const objData = useFullObjectsData(objectsIds);

  const handleUpload = async (e) => {
    const formData = new FormData();
    formData.append('config', e.target.files[0], `${props.objectId}.gml`);
    await http.post('/silos/import', formData);

    objData.refetch();
  };

  return (
    <Container className="d-flex flex-column justify-content-center">

      {(loadingObject?.length > 0 || loadingUser?.length > 0) && <Loader />}

      <Row className={show ? 'd-none' : ''}>
        {!props.withoutName
        && (
          <>
            <Col xs={12}>
              <h3 className="mb-1 text-center">{object?.name}</h3>
            </Col>

            <Col xs={12}>
              <h2 className="mb-1 text-center">Силос еще не настроен</h2>
            </Col>
          </>
        )}
        <Col xs={12} className="d-flex justify-content-center">
          <input
            ref={fileInputRef}
            type="file"
            className="d-none"
            onChange={handleUpload}
            accept=".gml"
          />
          <Button
            className="mt-2 mx-1"
            variable="primary"
            onClick={handleShow}
            disabled={data.user_type_id === 2}
          >
            Настроить
          </Button>
          <Button
            className="mt-2 mx-1"
            variant="outline-primary"
            onClick={handleRefUpload}
            disabled={data.user_type_id === 2}
          >
            Импортировать
          </Button>
        </Col>
      </Row>

      {props.modal
        ? (
          <NotConfiguredObjectModal
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
          />
        )
        : (
          <Row className={show ? '' : 'd-none'}>
            <Col xs={12}>
              <h3 className="mb-1 text-center">{object?.name}</h3>
            </Col>
            <Col className="d-flex justify-content-center">
              <FormConfig handleClose={handleClose} />
            </Col>
          </Row>
        )}

    </Container>

  );
};
