import { createSlice } from '@reduxjs/toolkit';

import {
  getLogs,
  getLogsKT,
  getStatusList,
  getReport,
} from '../../api/logs';
import { convertTempStringToArray } from '../../helpers';

export const logsSlice = createSlice({
  name: 'logs',
  initialState: {
    objectLogs: [],
    logsKt: [],
    curKT: null,
    loading: [],
    error: null,
    status: [],
    statusList: [],
  },
  reducers: {
    setLogsKT: (state, { payload }) => {
      state.logsKt = payload;
    },
    setObjectLogs: (state, { payload }) => {
      state.objectLogs = payload;
    },
    setCurrentKT: (state, { payload }) => {
      state.curKT = payload;
    },
  },
  extraReducers: {

    [getLogs.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getLogs.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.objectLogs = payload;
    },
    [getLogs.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getLogsKT.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getLogsKT.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      if (Array.isArray(payload)) {
        state.logsKt = payload;
      } else {
        const obj = {};

        Object.entries(payload).forEach((v) => {
          obj[v[0]] = convertTempStringToArray(v[1]);
        });

        state.logsKt = obj;
      }
    },
    [getLogsKT.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getStatusList.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getStatusList.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.statusList = payload;
    },
    [getStatusList.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getReport.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getReport.fulfilled]: (state) => {
      state.loading = state.loading.slice(0, -1);
    },
    [getReport.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
  },
});

export const {
  setLogsKT, setObjectLogs, setCurrentKT,
} = logsSlice.actions;
export default logsSlice.reducer;
