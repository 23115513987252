import { useState, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CrossIcon } from '../assets/Cross.svg';
import { ReactComponent as GearIcon } from '../assets/Gear.svg';
import { getUserGroupId } from '../helpers';
import { useFullObjectsData } from '../queries';
import { DeleteModal } from './index';

export const ObjectsTable = (props) => {
  const history = useHistory();
  const userGroupId = getUserGroupId();

  const [objectId, setObjectId] = useState(null);
  const [objectName, setObjectName] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = (id, name) => {
    setObjectId(id);
    setObjectName(name);
    setShowDeleteModal(true);
  };

  const {
    objects, loading,
  } = useSelector(({ object }) => object);
  const { currentUserId, data: userData } = useSelector(({ user }) => user);
  const { current: { companyId }} = useSelector(({ company }) => company);

  const objectsIds = useMemo(() => objects
    && objects?.map((object) => object.object_id), [objects]);

  const { data } = useFullObjectsData(objectsIds);

  const columns = [
    {
      dataField: 'object_id',
      text: '#',
      formatter: (_, row, idx) => idx + 1,
    },
    {
      dataField: 'name',
      text: 'Название',
    },
    {
      dataField: 'MAC',
      text: 'MAC',
    },
    {
      dataField: 'comment',
      text: 'Комментарий',
    },
    {
      dataField: 'do_alert_emails',
      text: 'Уведомления по email',
      formatter: (cell) => (cell ? 'Да' : 'Нет'),
    },
    {
      dataField: 'last_log[0].status',
      text: 'Статус',
      formatter: (cell, row) => {
        const objectStatuses = data?.filter((object) => object.object_id === row?.object_id)
          ?.map((object) => object?.status);

        if (objectStatuses?.includes('danger')) {
          return 'Аварийная температура';
        } if (objectStatuses?.includes('warning')) {
          return 'Повышенная температура';
        } if (objectStatuses?.includes('ok')) {
          return 'Ок';
        } if (objectStatuses?.includes('no_kt_addresses')) {
          return 'Нет термоподвесок';
        }
        if (objectStatuses?.length === 0) {
          return '-';
        }

        return 'Получение';
      },
    },
    {
      dataField: 'actions',
      text: '',
      formatter: (_, row) => (
        (props.usersObject)
          ? ''
          : (
            <div className="d-flex">
              <GearIcon
                className="me-2"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/objectProperties/${row.object_id}`, {
                    userGroupId: companyId,
                  });
                }}
              />
              <CrossIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleShowDeleteModal(row.object_id, row.name);
                }}
              />
            </div>
          )
      ),
      editCellStyle: {
        verticalAlign: 'middle',
      },
    },
  ];

  if (userData.user_type_id === 1 && !currentUserId) {
    return (
      <div className="mt-3">
        <h3>Выберите пользователя</h3>
      </div>
    );
  }

  if (userData.user_type_id === 0 && !userGroupId) {
    return (
      <div className="mt-3">
        <h3>Выберите компанию</h3>
      </div>
    );
  }

  const rowStyle = (row) => {
    const style = {};
    const objectStatuses = data?.filter((object) => object.object_id === row?.object_id)
      ?.map((object) => object?.status);

    if (objectStatuses?.includes('danger')) {
      style.backgroundColor = '#ff5733';
    } else if (objectStatuses?.includes('warning')) {
      style.backgroundColor = '#fff034';
    } else if (objectStatuses?.includes('ok')) {
      style.backgroundColor = '#00B900';
    }

    return style;
  };

  return (
    <div className="mt-3 overflow-auto">
      <BootstrapTable
        remote
        loading={loading.length > 0}
        className="table-responsive"
        keyField="object_id"
        data={objects || []}
        columns={columns}
        noDataIndication="Записей нет"
        bordered={false}
        rowStyle={rowStyle}
        hover
        rowEvents={{
          onClick: (_, row) => {
            history.push(`/objectPreview/${row.object_id}`);
          },
        }}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({ ...base, background: 'rgba(206, 206, 206, 0.5)' }),
          },
        })}
      />
      <DeleteModal
        show={showDeleteModal}
        title={`объект ${objectName}`}
        id={objectId}
        handleClose={handleCloseDeleteModal}
        handleShow={handleShowDeleteModal}
        item="object"
      />
    </div>
  );
};
