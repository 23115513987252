import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../config/axios';
import {
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  setRememberMe,
  setUserInfo,
} from '../helpers';

export const login = createAsyncThunk(
  'users/login',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.post('/login', payload);
      setRememberMe(data.rememberMe).then(() => {
        setUserInfo(data.userInfo);
        setAccessToken(data.accessToken);
        setRefreshToken(data.refreshToken);
      });

      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const checkAuth = createAsyncThunk(
  'users/checkAuth',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`/checkAuth?token=${getRefreshToken()}`);
      setUserInfo(data);

      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);
