import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

export const StatisticTable = (props) => {
  let currentColors = props.allSelectedColors;

  const hangeChange = (e, color) => {
    const { checked } = e.target;
    if (checked) {
      currentColors.push(color);
      props.setCurColor(currentColors.map((el) => el));
    } else {
      const newColors = currentColors.filter((el) => el !== color);
      currentColors = newColors;
      props.setCurColor(newColors);
    }
  };

  const hangeAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      props.setCurColor(props.allColors);
    } else {
      props.setCurColor([]);
    }
  };

  const columns = useMemo(() => [
    {
      dataField: '',
      text: 'Вкл',
      headerFormatter: (cell, row) => (
        <Form.Group controlId={`checkbox_${row.color}`} className="mb-0">
          <Form.Check
            name={`checkbox_${row.color}`}
            type="checkbox"
            label=""
            onChange={hangeAllChange}
            className="ps-0 customCheckboxRadio customCheckboxRadioTable"
          />
        </Form.Group>
      ),
      formatter: (cell, row) => (
        <Form.Group controlId={`checkbox_${row.color}`} className="mb-0" key={`checkbox_color_${row.color}`}>
          <Form.Check
            name={`checkbox_${row.color}`}
            type="checkbox"
            label=""
            checked={props.allSelectedColors.includes(row.color)}
            className="ps-0 customCheckboxRadio customCheckboxRadioTable"
            onClick={(e) => hangeChange(e, row.color)}
          />
        </Form.Group>
      ),
    },
    {
      dataField: 'color',
      text: 'Цвет',
      formatter: (cell) => (
        <div style={{
          width: '20px', height: '20px', borderRadius: '4px', background: cell,
        }}
        />
      ),
    },
    {
      dataField: 'idx',
      text: 'Индекс',
    },
    {
      dataField: 'minValue',
      text: 'Нижн. гр.',
    },
    {
      dataField: 'maxValue',
      text: 'Верх. гр.',
    },
    {
      dataField: 'lastValue',
      text: 'Последнее значение',
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [props.allSelectedColors]);

  return (
    <div className="overflow-auto">
      <BootstrapTable
        keyField="MAC"
        data={props.KTData || []}
        columns={columns}
        noDataIndication="Записей нет"
        bordered={false}
        hover
      />
    </div>
  );
};
