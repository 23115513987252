import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { getObjects } from '../api/object';
import {
  FilterChart,
  StatisticTable,
  Loader,
} from '../components';
import { convertTempStringToArray, colors } from '../helpers';
import { getUserGroupId } from '../helpers/getUserGroupId';
import { setLogsKT } from '../store/reducers/logs';

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    x: {
      ticks: {
        maxTicksLimit: 15,
      },
    },
  },
};

export const Statistics = () => {
  const dispatch = useDispatch();
  const userGroupId = getUserGroupId();

  const { logsKt, loading } = useSelector(({ logs }) => logs);

  const [KTData, setKTData] = useState([]);
  const [source, setSource] = useState(null);
  const [sensorsArray, setSensorsArray] = useState([]);
  const [currentColor, setCurrentColor] = useState([]);
  const labelForChart = useMemo(() => logsKt.map((el) => {
    const currentDate = new Date(el.timestamp).toLocaleDateString('ru');
    const currentTime = new Date(el.timestamp).toLocaleTimeString('ru');
    return [currentDate, currentTime];
  }).reverse(), [logsKt]);
  const prepareDataForChart = useMemo(() => sensorsArray.map((el, index) => ({
    label: `sensor_${index}`,
    data: el,
    fill: false,
    backgroundColor: colors[index],
    borderColor: colors[index],
  })), [sensorsArray]);
  const datasetsForChart = useMemo(() => prepareDataForChart
    .filter((el) => currentColor.includes(`${el.borderColor}`)), [currentColor, prepareDataForChart]);

  const chartData = {
    labels: labelForChart,
    datasets: datasetsForChart,
  };

  const allColors = useMemo(() => sensorsArray?.map((el, index) => colors[index]), [sensorsArray]);
  const allSelectedColors = useMemo(() => datasetsForChart?.map(
    (el) => el.backgroundColor,
  ), [datasetsForChart]);

  useEffect(() => {
    const localSensors = [];
    logsKt.forEach((el) => {
      setSource(el.KT_address);
      const sensorData = convertTempStringToArray(el?.sensors_data);
      localSensors.push(sensorData);
    });

    const KTArray = [];
    const localSensorsArray = [];

    localSensors[0]?.forEach((elem, idx) => {
      const sensorArray = localSensors.map((x) => x[idx]).reverse();
      localSensorsArray.push(sensorArray);

      KTArray.push({
        idx: idx + 1,
        maxValue: String([...sensorArray].sort((a, b) => b - a)[0]),
        minValue: String([...sensorArray].sort((a, b) => a - b)[0]),
        lastValue: String(sensorArray[sensorArray.length - 1]),
        color: colors[idx],
        sourse: source,
        allSelectedColors,
      });
    });

    setSensorsArray(localSensorsArray);
    setKTData(KTArray);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsKt, source, allSelectedColors.length]);

  useEffect(() => {
    if (userGroupId) {
      dispatch(getObjects({ user_group_id: userGroupId }));
    }
  }, [dispatch, userGroupId]);

  useEffect(() => {
    dispatch(setLogsKT([]));
  }, [dispatch]);

  return (
    <Container>
      {loading?.length > 0 && <Loader />}
      <Row className="justify-content-center"><h2 className="my-3"> Статистика</h2></Row>
      <Row>
        <Col>
          <Line data={chartData} options={options} />
        </Col>
      </Row>
      <FilterChart />
      <Row>
        <Col>
          <StatisticTable
            KTData={KTData}
            setCurColor={setCurrentColor}
            allColors={allColors}
            allSelectedColors={allSelectedColors}
          />
        </Col>
      </Row>
    </Container>
  );
};
