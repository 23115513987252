import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addUser, updateUser } from '../api/users';
import { Loader } from './index';

export const UserModal = (props) => {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [userTypeId, setUserTypeId] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [workPhone, setWorkPhone] = useState('');
  const [currentUser, setCurrentUser] = useState({});

  const { id } = useParams();
  const {
    loading, companyUsers, data,
  } = useSelector(({ user }) => user);

  useEffect(() => {
    if (props.currentUser) {
      setCurrentUser(data);
    } else {
      companyUsers.forEach((el) => {
        if (el.user_id === props.userId) {
          setCurrentUser(el);
        }
      });
    }
  }, [companyUsers, props.userId, props.currentUser, data]);

  const userGroupId = props.objectUsers || props.companyId ? props.companyId : id;
  const objectId = props.objectUsers ? id : null;

  const formData = {
    first_name: firstName,
    last_name: lastName,
    password,
    email,
    user_type_id: userTypeId,
    mobile_phone: mobilePhone,
    work_phone: workPhone,
    user_group_id: Number(userGroupId),
    user_id: props.userId,
  };

  const handleSubmit = () => {
    dispatch(addUser({ formData, objectId }));
    props.handleClose();
  };

  const editUser = () => {
    delete formData.password;
    delete formData.user_group_id;
    dispatch(updateUser(formData));
    props.handleClose();
  };

  // useEffect(() => {
  //  if (props.userId && !props.creacte) { dispatch(getUser({ user_id: props.userId })); }
  // }, [dispatch, props.userId, props.creacte]);

  useEffect(() => {
    setFirstName(currentUser ? currentUser.first_name : '');
    setLastName(currentUser ? currentUser.last_name : '');
    setEmail(currentUser ? currentUser.email : '');
    setUserTypeId(currentUser ? currentUser.user_type_id : '');
    setMobilePhone(currentUser ? currentUser.mobile_phone : '');
    setWorkPhone(currentUser ? currentUser.work_phone : '');
  }, [currentUser]);

  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Добавление / просмотр пользователя</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Container>
            {loading?.length > 0 && <Loader />}

            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="last_name" className="mb-3">
                  <Form.Label>Фамилия</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="first_name" className="mb-3">
                  <Form.Label>Имя</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Эл. почта</Form.Label>
                  <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {(!props.userId)
            && (
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="password" className="mb-3">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="mobile_phone" className="mb-3">
                  <Form.Label>Моб. тел</Form.Label>
                  <Form.Control
                    type="text"
                    value={mobilePhone}
                    onChange={(e) => { setMobilePhone(e.target.value); }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="work_phone" className="mb-3">
                  <Form.Label>Раб. тел</Form.Label>
                  <Form.Control
                    type="text"
                    value={workPhone}
                    onChange={(e) => { setWorkPhone(e.target.value); }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {
              (!props.currentUser)
              && (
                <Row>
                  <Col xs={12}><p>Роль пользователя</p></Col>
                  <Col xs={12}>
                    <Form.Group
                      className="d-flex align-items-center"
                      controlId="formClientRole"
                    >
                      <Form.Check
                        name="formClientRole"
                        className="customCheckboxRadio"
                        label="Client"
                        type="radio"
                        checked={userTypeId === 2}
                        onChange={() => setUserTypeId(2)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group
                      className="d-flex align-items-center"
                      controlId="formAdminRole"
                    >
                      <Form.Check
                        name="formAdminRole"
                        className="customCheckboxRadio"
                        label="Admin"
                        type="radio"
                        checked={userTypeId === 1}
                        onChange={() => setUserTypeId(1)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )
            }
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={props.handleClose}
        >
          Отмена
        </Button>
        <Button
          variant="primary"
          disabled={props.userType === 2}
          onClick={(props.userId) ? editUser : handleSubmit}
        >
          {(props.userId) ? 'Изменить' : 'Сохранить'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
