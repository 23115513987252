import { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

export const CustomToast = () => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState({});

  const router = useRouteMatch();

  const userError = useSelector(({ user }) => user.error);
  const logsError = useSelector(({ logs }) => logs.error);
  const configError = useSelector(({ config }) => config.error);
  const companyError = useSelector(({ company }) => company.error);

  const handleError = (err) => {
    if (err && err.status !== 403 && router.url !== '/') {
      setShow(true);
      setError(err);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    handleError(userError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userError]);

  useEffect(() => {
    handleError(logsError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsError]);

  useEffect(() => {
    handleError(configError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configError]);

  useEffect(() => {
    handleError(companyError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyError]);

  return (
    <div className="customToast">
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">Ошибка</strong>
        </Toast.Header>
        <Toast.Body>
          {error.message || 'Неизвестная ошибка'}
        </Toast.Body>
      </Toast>
    </div>
  );
};
