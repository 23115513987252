import { Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Temperature } from '../assets/temperature.svg';
import { ReactComponent as Wheat } from '../assets/wheat.svg';
import { getSilosTypeIcon } from '../helpers';

export const SilosCard = ({
  name, type, maxTemp, level, humidity, status, culture, objectId,
}) => {
  const history = useHistory();

  const getLevelText = (level) => {
    if (level < 0) {
      return 'ошибка';
    }
    if (!level) {
      return '-';
    }

    return `${level}%`;
  };

  return (
    <Card className="silosCard">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div className="h5 mb-0">{name}</div>
        <Button
          variant="primary"
          style={{ padding: '2px 8px' }}
          onClick={() => {
            history.push({
              pathname: `/object/${objectId}`,
              search: `?silosName=${name}`,
            });
          }}
        >
          Смотреть
        </Button>
      </Card.Header>

      <Card.Body className="d-flex justify-content-center align-items-center">
        {getSilosTypeIcon(type, status)}
        <div className="ms-5">
          {culture && (
            <div className="d-flex align-items-center">
              <Wheat
                width={16}
                height={16}
                className="me-1"
              />
              <span>{culture}</span>
            </div>
          )}
          <div className={level < 0 && 'text-danger'}>
            <span className="font-weight-bold me-1">
              Уровень:
            </span>
            <span>{getLevelText(level)}</span>
          </div>
          <div>
            <span className="font-weight-bold me-1">
              Влажность:
            </span>
            <span>{humidity ? `${humidity}%` : '-'}</span>
          </div>
        </div>
      </Card.Body>

      <Card.Footer style={{ background: 'none' }}>
        <div
          className="d-flex flex-column align-items-center mb-2"
        >
          <Temperature width={24} height={24} />
          <span>Температура</span>
        </div>

        <div>
          <div>
            <span className="font-weight-bold me-1">
              Макс. температура:
            </span>
            <span>{maxTemp ? `${maxTemp}C` : '-'}</span>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};
