export const downloadFile = (objectName, silosName, contentBase64) => {
  objectName = objectName.split(' ').join('_');
  const [type] = contentBase64.split(';');
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);

  downloadLink.href = contentBase64;
  downloadLink.target = '_self';
  if (type === 'data:application/gml+xml') {
    downloadLink.download = `${objectName}_${new Date().toISOString()}.gml`;
  } else {
    downloadLink.download = `${objectName}_${silosName}_${new Date().toISOString()}.pdf`;
  }
  downloadLink.click();
};
