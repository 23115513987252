import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as CrossIcon } from '../assets/Cross.svg';
import { ReactComponent as GearIcon } from '../assets/Gear.svg';
import { setUserGroupId } from '../helpers/setUserGroupId';
import { setCurrentCompanyId } from '../store/reducers/company';
import { DeleteModal } from './index';

export const NavItemSuperUser = (props) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  const fetchObjects = (id) => {
    dispatch(setCurrentCompanyId(id || null));
    setUserGroupId(props.id);
  };

  return (
    <Nav.Item
      key={`key-${props.id}`}
      className="d-flex justify-content-between align-items-center"
    >
      <Nav.Link
        className={props.id === Number(props.currentId) ? 'active text-primary' : 'text-primary'}
        onClick={() => fetchObjects(props.id)}
      >
        {props.name}
      </Nav.Link>
      <div className="d-flex">
        <Link
          className="d-flex p-0 me-2"
          to={{
            pathname: `company/${props.id}`,
          }}
          style={{ cursor: 'pointer' }}
        >
          <GearIcon />
        </Link>

        <CrossIcon style={{ cursor: 'pointer' }} onClick={handleShowDeleteModal} />

      </div>

      <DeleteModal
        show={showDeleteModal}
        title={`компанию ${props.name}`}
        id={props.id}
        handleClose={handleCloseDeleteModal}
        handleShow={handleShowDeleteModal}
        item="company"
      />
    </Nav.Item>
  );
};
