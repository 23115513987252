import { Card } from 'react-bootstrap';

import mapMask from '../assets/mapMask.svg';

export const WeatherInfoCard = ({ temp, humidity }) => (
  <Card className="object-card mb-4">
    <Card.Body>
      <Card.Title className="mb-2">Погода</Card.Title>
      <div className="map-background" style={{ backgroundImage: `url(${mapMask})` }}>
        <div className="d-flex w-100 h-100 flex-column">
          <div className="weigher-container">
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column align-items-center me-5">
                <div>
                  <span className="me-1">{temp}</span>
                  <span>C</span>
                </div>
                <div>температура</div>
              </div>

              <div className="d-flex flex-column align-items-center">
                <div>
                  <span className="me-1">{humidity}</span>
                  <span>%</span>
                </div>
                <div>влажность</div>
              </div>
            </div>
          </div>

          <div>
            <div className="h6">Погода с метео станции</div>
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column align-items-center me-5">
                <div>
                  <span className="me-1">-</span>
                  <span>C</span>
                </div>
                <div>температура</div>
              </div>

              <div className="d-flex flex-column align-items-center">
                <div>
                  <span className="me-1">-</span>
                  <span>%</span>
                </div>
                <div>влажность</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card.Body>
  </Card>
);
