/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import isEmpty from 'lodash/isEmpty';
import {
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { usePersistentState } from 'react-persistent-state';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { MOUSE, Vector3 } from 'three';
import { updateConfig, getConfig } from '../api/config';
import { getKTColor, convertFormDataToObject } from '../helpers';
import { useFullObjectsData } from '../queries';
import { setCurrentKT, setCurrentSilos } from '../store/reducers/config';
import { SetupSilcorpus } from './Three';
import { Silos } from './Three/Silos';
import { Loader } from './index';

export const SilosRound = ({
  name,
  config = [],
  hasCenter,
  minRadius = 50,
  formRef,
  view,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { search } = useLocation();
  const silosName = new URLSearchParams(search).get('silosName');
  const {
    volumes, loading,
  } = useSelector(({ config }) => config);

  const computeRadius = (k) => {
    const r = minRadius * (k + 1);

    return {
      width: `${r * 2}px`,
      height: `${r * 2}px`,
      zIndex: minRadius - k,
    };
  };

  const computeKTPosition = (k, r, count) => {
    const f = (360 * k) / count.length;
    const fi = f * (Math.PI / 180);

    const x = r + r * Math.cos(fi);
    const y = r + r * Math.sin(fi);

    return {
      top: `${y}px`,
      left: `${x}px`,
    };
  };

  const avgVolume = Object.keys(volumes || {}).length ? Object.values(volumes).map(({ volume }) => volume).reduce((a, b) => (a + b), 0) / Object.keys(volumes).length : 0;

  const [lastClick, setLastClick] = useState(0);

  const {
    objects,
  } = useSelector(({ object }) => object);

  const objectsIds = useMemo(() => objects
    && objects?.map((object) => object.object_id), [objects]);

  const objData = useFullObjectsData(objectsIds);

  const temperatures = objData?.data
    ?.filter((o) => o.object_id === Number(id)).find((o) => o.name === name);

  const handleKTClick = (e, kt) => {
    // предотващать несколько срабатываний функции
    // потому что у квадратов сквозной ивент
    if (lastClick >= (Date.now() - 200)) { return; }

    setLastClick(Date.now());

    if (e) {
      e.preventDefault();

      if (e.target.value) {
        e.target.blur();
      }
    }
    const options = temperatures?.options?.find((t) => t.KT_address === kt.addr) ?? {};

    dispatch(setCurrentSilos({ name }));
    dispatch(setCurrentKT({
      addr: kt.addr === 'NaN' ? '' : kt.addr || '',
      alias: kt.alias === 'NaN' ? '' : kt.alias || '',
      ...options,
    }));
  };

  const handleKTDoubleClick = (e, kt) => {
    e.preventDefault();
    e.target.nextSibling.type = 'text';
    e.target.nextSibling.hidden = false;
    e.target.nextSibling.focus();
    e.target.nextSibling.defaultValue = kt.addr === 'NaN' ? '' : kt.addr;
    e.target.hidden = true;
  };

  const handleKTBlur = (e, alias) => {
    e.preventDefault();
    e.target.hidden = true;
    e.target.previousSibling.hidden = false;
    if (!alias) {
      e.target.previousSibling.innerText = e.target.value;
    }
  };

  const handleSave = (e) => {
    const formDataEntries = Object.entries(convertFormDataToObject(e.target.form));
    const updatedConfig = Array(config.length).fill(null).map(() => []);

    formDataEntries.forEach(([inputName, value]) => {
      const [row, cell] = inputName.split('_').slice(-2);
      updatedConfig[row][cell] = value;
    });

    dispatch(updateConfig({
      object_id: id,
      silos_name: silosName,
      kt_addresses: updatedConfig,
    }));

    setTimeout(() => dispatch(getConfig({ object_id: id, silos_name: silosName })), [1000]);
  };

  const controlRef = useRef();

  const [showEdges, setShowEdges] = usePersistentState(false, 'silos_edges');

  if (loading.length > 0) {
    return <Loader />;
  }

  if (view === '3d') {
    return (
      <>
        <div style={{
          display: 'grid', justifyContent: 'flex-end', gap: 16, position: 'absolute', bottom: 8, right: 8, zIndex: 10000,
        }}
        >
          <Button
            onClick={() => controlRef.current?.reset()}
            style={{
              width: 'auto',
            }}
          >
            Вернуть камеру
          </Button>
          <Button
            onClick={() => setShowEdges(!showEdges)}
            style={{
              width: 'auto',
            }}
          >
            {showEdges ? 'Скрыть' : 'Показать'}
            {' '}
            четкие границы
          </Button>
        </div>
        <SetupSilcorpus
          cameraPosition={new Vector3(-1 * config.length, 5, 5 * config.length)}
          controlsProps={{
            ref: controlRef,
            enablePan: false,
            maxPolarAngle: Math.PI / 2 - 0.13,
            minPolarAngle: Math.PI / 3,
            screenSpacePanning: false,
            mouseButtons: {
              RIGHT: MOUSE.LEFT,
            },
          }}
        >
          <Silos
            volumes={volumes}
            vol={avgVolume.toFixed(2)}
            edges={showEdges}
            hasCenter={hasCenter}
            temperatures={temperatures}
            config={config}
            onClickKT={(addr) => handleKTClick(undefined, addr)}
          />
        </SetupSilcorpus>
      </>
    );
  }

  return (
    <div
      className={`silos-container s${name} round mx-auto`}
      style={{
        minWidth: (minRadius * 2 * (hasCenter ? config.length - 1 : config.length))
          + config.length * 5,
        height: ((minRadius * 2 * (hasCenter ? config.length - 1 : config.length))
          + config.length * 6) + 50,
      }}
    >
      <form ref={formRef}>
        <div className="d-flex" style={{ height: 'inherit' }}>
          { config.map((radius, i) => {
            if (hasCenter && i === 0) {
              return (
                <div className={`little center ${getKTColor(temperatures, radius[i])}`} key={`r_${name}_${i}`}>
                  <div
                    onDoubleClick={(e) => {
                      handleKTDoubleClick(e, radius[i]);
                    }}
                    onClick={(e) => handleKTClick(e, radius[i])}
                    className="text-center"
                    style={{
                      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
                    }}
                  >
                    {(radius[i].alias === 'NaN' ? '' : radius[i].alias) || (radius[i].addr === 'NaN' ? '' : radius[i].addr)}
                  </div>
                  <input
                    name={`kt_${i}_0`}
                    type="hidden"
                    onBlur={(e) => handleKTBlur(e, radius[i].alias)}
                    defaultValue={radius[i].addr}
                    style={{
                      fontSize: '9px',
                      borderRadius: '50%',
                      height: '100%',
                    }}
                  />
                </div>
              );
            }
            return (
              <div
                key={`r_${name}_${i}`}
                className={`big r${hasCenter ? i : i + 1}`}
                style={computeRadius(hasCenter ? i - 1 : i)}
              >
                { radius.map((v, j) => (
                  <div
                    className={`little ${getKTColor(temperatures, v)}`}
                    style={{ ...computeKTPosition(j, minRadius * (hasCenter ? i : i + 1), radius) }}
                  >
                    <div
                      style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
                      }}
                      onDoubleClick={(e) => {
                        handleKTDoubleClick(e, v);
                      }}
                      onClick={(e) => handleKTClick(e, v)}
                      className="text-center"
                    >
                      {(v?.alias === 'NaN' ? '' : v?.alias) || (v?.addr === 'NaN' ? '' : v?.addr)}
                    </div>
                    <input
                      name={`kt_${i}_${j}`}
                      type="hidden"
                      onBlur={(e) => handleKTBlur(e, v?.alias)}
                      defaultValue={v?.addr}
                      style={{
                        fontSize: '9px',
                        borderRadius: '50%',
                        height: '100%',
                      }}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <Row>
          <Col className="d-flex justify-content-end mt-2">
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={isEmpty(temperatures)}
            >
              Сохранить
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};
