import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, HashRouter as Router } from 'react-router-dom';
import { checkAuth } from './api/auth';
import { getObjects } from './api/object';
import { CustomToast, Navigation } from './components';
import { PrivateRoute, routes } from './config/router';
import './App.scss';
import {
  getRefreshToken,
  setUserGroupId,
  getUserGroupId,
} from './helpers';

const App = () => {
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const userGroupId = getUserGroupId();

  useEffect(() => {
    if (getRefreshToken()) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  useEffect(() => {
    if (userGroupId) {
      dispatch(getObjects({ user_group_id: userGroupId }));
    }
  }, [dispatch, userGroupId]);

  useEffect(() => {
    if (user?.data?.user_type_id && user?.data?.user_type_id !== 0) {
      setUserGroupId(user.data.user_group_id);
    }
  }, [user?.data?.user_group_id, user?.data?.user_type_id]);

  return (
    <Router>
      <Navigation />
      <main style={{ display: 'flex', marginTop: 72 }}>
        <CustomToast />
        { routes.map(({ auth, ...route }, i) => (
          auth ? (
            <PrivateRoute user={user} key={`route-${i}`} {...route} />
          ) : (
            <Route key={`route-${i}`} {...route} />
          )
        ))}
      </main>
    </Router>
  );
};

export default App;
