import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOneCompany } from '../api/company';
import { CompanyProperies } from '../components';

export const Company = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getOneCompany(id));
  }, [dispatch, id]);

  return <CompanyProperies />;
};
