import { useEffect, useState } from 'react';
import { Button, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getCompany } from '../api/company';
import { getObjects } from '../api/object';
import { ReactComponent as MenuIcon } from '../assets/menu.svg';
import { getUserGroupId } from '../helpers';
import { setShowSidebar } from '../store/reducers/appSettings';
import {
  InputSearch,
  NavItemSuperUser,
  Loader,
} from './index';

export const SidebarSuperUser = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userGroupId = getUserGroupId();
  const { companies, loading } = useSelector(({ company }) => company);

  const { showSidebar } = useSelector(({ appSettings }) => appSettings);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    dispatch(getCompany());
  }, [dispatch]);

  useEffect(() => {
    if (userGroupId) {
      dispatch(getObjects({ user_group_id: userGroupId }));
    }
  }, [dispatch, userGroupId]);

  const handleSearch = (searchStr) => {
    setInputValue(searchStr);
  };

  let localCompanies = [...companies];

  if (inputValue.length > 0) {
    localCompanies = companies.filter((el) => {
      const { name } = el;
      return (name.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1);
    });
  }

  return (
    <div className={`sidebar-wrapper p-0 ${showSidebar ? '' : 'sidebar-wrapper--hide'}`}>
      <Nav className={`sidebar p-3
          ${showSidebar ? '' : 'sidebar--hide'}`}
      >
        <div className="d-flex mb-3">
          <Button
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
            className="toggleSidebar me-3"
          >
            <MenuIcon width={26} height={26} />
          </Button>
          <InputSearch inputValue={inputValue} change={(searchStr) => handleSearch(searchStr)} />
        </div>

        {loading?.length > 0 && <Loader />}

        <div className="link-container">
          {
            (!localCompanies)
              ? <h3>Записей нет</h3>
              : localCompanies.map((userGroup) => (
                <NavItemSuperUser
                  key={`key-${userGroup.user_group_id}`}
                  id={userGroup?.user_group_id}
                  currentId={userGroupId}
                  name={userGroup?.name}
                />
              ))
          }
        </div>
        <Link
          to={{
            pathname: '/company',
            state: { from: location.pathname, isNew: true },
          }}
        >
          <Button
            variant="primary"
            className="w-100"
          >
            Добавить клиента
          </Button>
        </Link>
      </Nav>

    </div>
  );
};
