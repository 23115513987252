import { InputGroup, FormControl } from 'react-bootstrap';

export const InputSearch = (props) => {
  const handleChange = (e) => {
    props.change(e.target.value);
  };

  return (
    <InputGroup>
      <FormControl
        placeholder="Поиск"
        aria-label="search"
        onChange={(e) => handleChange(e)}
        value={props.inputValue}
      />
    </InputGroup>
  );
};
