import { useEffect, useState } from 'react';
import {
  Form,
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addConfig } from '../api/config';
import { getObjectsSiloses } from '../api/object';
import { convertFormDataToObject } from '../helpers';

export const FormConfig = (props) => {
  const [isRound, setIsRound] = useState(false);
  const [selectedRound, setSelectedRound] = useState(2);
  const [recurringSilosName, setRecurringSilosName] = useState([]);
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const { objectId } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addConfig(convertFormDataToObject(e.target)));

    setTimeout(() => dispatch(getObjectsSiloses({ object_id: objectId })), [1000]);
  };

  const { silosEnum } = useSelector(({ config }) => config);

  useEffect(() => {
    setRecurringSilosName(Object.keys(silosEnum).filter((el) => el === name));
  }, [silosEnum, name]);

  return (
    <Form onSubmit={handleSubmit} style={{ maxWidth: '500px' }}>
      <Row className="justify-content-center">
        <Col xs={12} className="mb-3">
          <Form.Control
            className="d-none"
            value={objectId}
            name="object_id"
          />
          <Form.Group className="d-flex justify-content-center mt-2">
            <Form.Check
              type="radio"
              label="Прямоугольный"
              id="radio2"
              className="me-3 customCheckboxRadio"
              checked={!isRound}
              onChange={() => setIsRound(false)}
            />
            <Form.Check
              type="radio"
              label="Круглый"
              id="radio1"
              className="customCheckboxRadio"
              checked={isRound}
              onChange={() => setIsRound(true)}
            />
          </Form.Group>
        </Col>
        { isRound ? (
          <Col xs={12} className="d-flex justify-content-center">
            <Row>
              <Form.Control
                className="d-none"
                value="0"
                name="type"
              />
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Row>
                    <Col sm={7}>
                      <Form.Label>Название</Form.Label>
                    </Col>
                    <Col sm={5}>
                      <Form.Control
                        name="silos_name"
                        type="text"
                        value={name}
                        isInvalid={recurringSilosName.length > 0}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                    <Col sm={12}>
                      <Form.Control.Feedback type="invalid">Такое название уже используется</Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col sm={12}>
                { Array(selectedRound).fill(null).map((el, index) => (
                  <Form.Group key={el} className="mb-3">
                    <Row>
                      <Col sm={7}>
                        <Form.Label>{`Кол-во термоподвесок R${index + 1}`}</Form.Label>
                      </Col>
                      <Col sm={5}>
                        <Form.Control
                          name={`r${index + 1}`}
                          type="number"
                          min="1"
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ))}
              </Col>

              <Col sm={12}>
                <Form.Group className="mb-3 pt-3 border-top">
                  <Row>
                    <Col sm={7}>
                      <Form.Label>Высота цилиндра</Form.Label>
                    </Col>
                    <Col sm={5}>
                      <Form.Control
                        name="h1"
                        type="number"
                        min="1"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className="mb-3 pb-3">
                  <Row>
                    <Col sm={7}>
                      <Form.Label>Высота конуса</Form.Label>
                    </Col>
                    <Col sm={5}>
                      <Form.Control
                        name="h2"
                        type="number"
                        min="1"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>

              <Col sm={12} className="d-flex justify-content-between mb-3">
                <Button
                  type="button"
                  variant="outline-primary"
                  className="w-100"
                  onClick={() => setSelectedRound(selectedRound + 1)}
                >
                  Добавить радиус
                </Button>
              </Col>

              <Col sm={12}>
                <Form.Group controlId="c" className="mb-3 d-flex justify-content-center">
                  <Form.Check
                    name="c"
                    type="checkbox"
                    className="customCheckboxRadio"
                    label="Центр"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col xs={12} className="d-flex justify-content-center">
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Row>
                    <Col sm={7}>
                      <Form.Label>Название</Form.Label>
                    </Col>
                    <Col sm={5}>
                      <Form.Control
                        name="silos_name"
                        type="text"
                        value={name}
                        isInvalid={recurringSilosName.length > 0}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                    <Col sm={12}>
                      <Form.Control.Feedback type="invalid">Такое название уже используется</Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Row>
                    <Col sm={7}>
                      <Form.Label>Кол-во термоподвесок по ширине</Form.Label>
                    </Col>
                    <Col sm={5}>
                      <Form.Control
                        name="x"
                        type="number"
                        min="1"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className="mb-3 pb-3 border-bottom">
                  <Row>
                    <Col sm={7}>
                      <Form.Label>Кол-во термоподвесок по высоте</Form.Label>
                    </Col>
                    <Col sm={5}>
                      <Form.Control
                        name="y"
                        type="number"
                        min="1"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Row>
                    <Col sm={7}>
                      <Form.Label>Высота</Form.Label>
                    </Col>
                    <Col sm={5}>
                      <Form.Control
                        name="h1"
                        type="number"
                        min="1"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Form.Control
                className="d-none"
                value="1"
                name="type"
              />
            </Row>
          </Col>
        )}
        <Col xs={12} className="d-flex justify-content-center">
          <Button
            variant="primary"
            type="submit"
            disabled={!name}
            onClick={() => props.handelCloseModel()}
          >
            Применить
          </Button>
        </Col>
      </Row>
    </Form>

  );
};
