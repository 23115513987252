import { useEffect, useState } from 'react';
import { Nav, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersCompany } from '../api/users';
import { ReactComponent as MenuIcon } from '../assets/menu.svg';
import { setShowSidebar } from '../store/reducers/appSettings';
import { setCurrentUser } from '../store/reducers/user';
import {
  InputSearch,
  NavItemAdmin,
  UserModal,
  Loader,
} from './index';

export const SidebarAdmin = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');

  const {
    loading, currentUserId, companyUsers, data,
  } = useSelector(({ user }) => user);
  const { showSidebar } = useSelector(({ appSettings }) => appSettings);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addUser = () => {
    dispatch(setCurrentUser(null));
    handleShow();
  };

  const handleSearch = (searchStr) => {
    setInputValue(searchStr);
  };

  useEffect(() => {
    dispatch(getUsersCompany({}));
  }, [dispatch]);

  let localCompanies = [...companyUsers];

  if (inputValue.length > 0) {
    localCompanies = companyUsers.filter((el) => {
      const { last_name } = el;
      return (last_name.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1);
    });
  }

  return (
    <div className={`sidebar-wrapper p-0 ${showSidebar ? '' : 'sidebar-wrapper--hide'}`}>
      <Nav className={`sidebar p-3 ${showSidebar ? '' : 'sidebar--hide'}`}>
        <div className="d-flex mb-3">
          <Button
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
            className="toggleSidebar me-3"
          >
            <MenuIcon width={26} height={26} />
          </Button>
          <InputSearch inputValue={inputValue} change={(searchStr) => handleSearch(searchStr)} />
        </div>

        {loading?.length > 0 && <Loader />}

        <div className="link-container">
          {
            (!localCompanies)
              ? <h3>Записей нет</h3>
              : (
                <>
                  <NavItemAdmin
                    key="key-me"
                    id={data.user_id}
                    groupId={data.user_group_id}
                    isActive={currentUserId === data.user_id}
                    name="Все объекты"
                  />
                  <hr style={{ margin: 5 }} />

                  {localCompanies.map((companyUser) => {
                    if (companyUser.user_id === data.user_id) {
                      return null;
                    }
                    return (
                      <NavItemAdmin
                        key={`key-${companyUser.user_id}`}
                        id={companyUser?.user_id}
                        isActive={currentUserId === companyUser.user_id}
                        name={`${companyUser.last_name} ${companyUser.first_name.slice(0, 1)}.`}
                      />
                    );
                  })}
                </>
              )
          }
        </div>
        <Button
          variant="primary"
          className="w-100"
          onClick={addUser}
        >
          Добавить пользователя
        </Button>
        <UserModal
          show={show}
          companyId={data.user_group_id}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      </Nav>
    </div>
  );
};
