import { DoubleSide } from 'three';

export const PlaneBox = ({
  args, position, opacity, ...props
}) => {
  const [posX, posY, posZ] = position;
  const [argX, argY, argZ] = args;

  return (
    <>
      <mesh position={[posX, posY, posZ + argZ / 2]} {...props}>
        <planeBufferGeometry attach="geometry" args={[argX, argY]} />
        <meshBasicMaterial
          side={DoubleSide}
          attach="material"
          color="orange"
          opacity={opacity}
          transparent
        />
      </mesh>
      <mesh position={[posX, posY, posZ - argZ / 2]} {...props}>
        <planeBufferGeometry attach="geometry" args={[argX, argY]} />
        <meshBasicMaterial
          side={DoubleSide}
          attach="material"
          color="orange"
          opacity={opacity}
          transparent
        />
      </mesh>
      <mesh position={[posX - argX / 2, posY, posZ]} rotation={[0, Math.PI / 2, 0]} {...props}>
        <planeBufferGeometry attach="geometry" args={[argZ, argY]} />
        <meshBasicMaterial
          side={DoubleSide}
          attach="material"
          color="orange"
          opacity={opacity}
          transparent
        />
      </mesh>
      <mesh position={[posX + argX / 2, posY, posZ]} rotation={[0, Math.PI / 2, 0]} {...props}>
        <planeBufferGeometry attach="geometry" args={[argZ, argY]} />
        <meshBasicMaterial
          side={DoubleSide}
          attach="material"
          color="orange"
          opacity={opacity}
          transparent
        />
      </mesh>
      <mesh position={[posX, posY - argY / 2, posZ]} rotation={[Math.PI / 2, 0, 0]} {...props}>
        <planeBufferGeometry attach="geometry" args={[argX, argZ]} />
        <meshBasicMaterial
          side={DoubleSide}
          attach="material"
          color="orange"
          opacity={opacity}
          transparent
        />
      </mesh>
    </>
  );
};
