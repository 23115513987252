import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import { getObjects } from '../api/object';
import { FilterChart, Loader } from '../components';
import { convertTempStringToArray, getUserGroupId } from '../helpers';
import { setLogsKT } from '../store/reducers/logs';

const arrayColumn = (arr, n) => arr.map((v) => v[n]);

export const Trends = () => {
  const dispatch = useDispatch();
  const userGroupId = getUserGroupId();

  const [sensors, setSensors] = useState([]);
  const [warningTemp, setWarningTemp] = useState(null);
  const [dangerTemp, setDangerTemp] = useState(null);
  const [maxTemp, setMaxTemp] = useState(0);
  const [minTemp, setMinTemp] = useState(0);
  const [avgTemp, setAvgTemp] = useState(0);

  const {
    logsKt,
    curKT, showLoader: loadingLogs,
  } = useSelector(({ logs }) => logs);
  const {
    silosEnum,
    showLoader: loadingConfig,
  } = useSelector(({ config }) => config);

  const d = useMemo(() => convertTempStringToArray(logsKt[0]?.sensors_data), [logsKt]);

  const options = {
    plugins: {
      legend: {
        labels: {
          filter: ({ datasetIndex }) => {
            const idx = datasetIndex;
            return idx === 0 || idx === 1 || idx === 2 || idx === 3;
          },
        },
        onClick: null,
      },
      tooltip: {
        callbacks: {
          title: (item) => item[3]?.raw + item[4]?.raw + item[5]?.raw,
          label: () => null,
          beforeBody: (item) => {
            const m = logsKt.map((v) => convertTempStringToArray(v.sensors_data));
            const l = arrayColumn(m, item[0].dataIndex).filter((v) => !!v).map((v) => +v);

            const min = Math.min(...l).toFixed(1);
            const max = Math.max(...l).toFixed(1);
            const avg = (l.reduce((a, b) => a + b, 0) / l.length).toFixed(1);

            return `сред: ${avg}\nмин: ${min}\nмакс: ${max}`;
          },
        },
      },
    },
    indexAxis: 'y',
    // responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  useEffect(() => {
    const sensorsData = logsKt.map((v) => convertTempStringToArray(v.sensors_data));
    const sensorsDataColumns = Array(sensorsData[0]?.length)
      .fill(null).map((_, i) => arrayColumn(sensorsData, i));

    const temps = sensorsDataColumns.map((k) => {
      const g = k.filter((v) => !!v).map((v) => +v);

      return {
        max: Math.max(...g).toFixed(1),
        min: Math.min(...g).toFixed(1),
        avg: (g.reduce((a, b) => a + b, 0) / g.length).toFixed(1),
      };
    });

    setMaxTemp(temps.map((v) => v.max - v.avg));
    setMinTemp(temps.map((v) => +v.min));
    setAvgTemp(temps.map((v) => v.avg - v.min));
  }, [logsKt]);

  const chartData = {
    labels: d.map((_, i) => i + 1) || [],
    datasets: [
      {
        type: 'scatter',
        label: 'Минимальная',
        data: minTemp,
        backgroundColor: '#f0f',
      },
      {
        type: 'scatter',
        label: 'Средняя',
        data: avgTemp,
        backgroundColor: '#34ffce',
      },
      {
        type: 'scatter',
        label: 'Максимальная',
        data: maxTemp,
        backgroundColor: '#00f',
      },
      {
        label: 'Текущая температура',
        data: arrayColumn(sensors, 0),
        backgroundColor: '#28a745',
      },
      {
        label: 'Повышенная',
        data: arrayColumn(sensors, 1),
        backgroundColor: '#fff034',
      },
      {
        label: 'Критическая',
        data: arrayColumn(sensors, 2),
        backgroundColor: '#ff5733',
      },
    ],
  };

  useEffect(() => {
    const curConfig = Object.values(silosEnum)
      .map((el) => el.config).flat().flat()
      .find((elem) => elem?.addr === curKT);

    setWarningTemp(curConfig?.warning || 30);
    setDangerTemp(curConfig?.danger || 50);
  }, [silosEnum, curKT]);

  useEffect(() => {
    if (userGroupId) {
      dispatch(getObjects({ user_group_id: userGroupId }));
    }
  }, [dispatch, userGroupId]);

  useEffect(() => {
    const sensorsDataset = d.map((el) => {
      const v = +el;

      return [
        v < warningTemp ? v : warningTemp,
        // eslint-disable-next-line no-nested-ternary
        v >= dangerTemp ? dangerTemp - warningTemp : v > warningTemp ? v - warningTemp : 0,
        v >= dangerTemp ? v - dangerTemp : 0,
      ];
    });

    setSensors(sensorsDataset);
  }, [d, dangerTemp, warningTemp]);

  useEffect(() => {
    dispatch(setLogsKT([]));
  }, [dispatch]);

  return (
    <Container>

      {(loadingConfig?.length || loadingLogs?.length > 0) > 0 && <Loader />}

      <Row className="justify-content-center"><h2 className="my-3"> Тренды</h2></Row>
      <Row>
        <Col>
          <Bar data={chartData} options={options} />
        </Col>
      </Row>
      <FilterChart curKT={curKT} trends />
      <Row>
        <Col />
      </Row>
    </Container>
  );
};
