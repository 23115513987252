import { createSlice } from '@reduxjs/toolkit';

import {
  getConfig,
  addConfig,
  setSettings,
  updateConfig,
  getTemperatures,
  importConfig,
  setSettingsBulk,
  getVolumes,
} from '../../api/config';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    silosEnum: {},
    error: null,
    loading: [],
    currentSilos: {},
    currentKT: {},
    temperatures: {},
    volumes: {},
    ktAddresses: [],
  },
  reducers: {
    resetConfig(state) {
      state.silosEnum = {};
      state.temperatures = {};
      state.currentSilos = {};
      state.currentKT = {};
      state.error = null;
    },
    setCurrentKT(state, { payload }) {
      state.currentKT = payload;
    },
    setCurrentSilos(state, { payload }) {
      state.currentSilos = payload;
    },
    setKtAddresses(state, { payload }) {
      state.ktAddresses = payload;
    },
  },
  extraReducers: {
    [getConfig.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getConfig.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.silosEnum = payload;
    },
    [getConfig.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [addConfig.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [addConfig.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.silosEnum = {
        // ...state.silosEnum,
        ...payload,
      };
    },
    [addConfig.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [updateConfig.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [updateConfig.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
    },
    [updateConfig.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [importConfig.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [importConfig.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.silosEnum = payload;
    },
    [importConfig.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [getTemperatures.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getTemperatures.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.temperatures = payload;
    },
    [getTemperatures.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [setSettings.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [setSettings.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      const {
        data, object_id, ...updatedCurrentKT
      } = payload;
      state.silosEnum = data;

      // да, это плохо, но иначе прям мучение
      const input = document.querySelector(`input[data-alias='${state.currentKT.alias}']`)
                 || document.querySelector(`input[data-addr='${state.currentKT.addr}']`);
      input.value = updatedCurrentKT.alias;
      state.currentKT = updatedCurrentKT;
    },
    [setSettings.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [setSettingsBulk.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [setSettingsBulk.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      const {
        data,
      } = payload;
      state.silosEnum = data;
    },
    [setSettingsBulk.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [getVolumes.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getVolumes.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.volumes = payload;
    },
    [getVolumes.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
  },
});

export const {
  setCurrentKT,
  setCurrentSilos,
  resetConfig,
  setKtAddresses,
} = configSlice.actions;
export default configSlice.reducer;
