import { createSlice } from '@reduxjs/toolkit';
import { checkAuth, login } from '../../api/auth';
import {
  addUser,
  getUsersCompany,
  getUser,
  updateUser,
  getObjectUser,
  deleteUser,
} from '../../api/users';
import { attachUser, detachUser } from '../../api/usersObject';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    checking: false,
    data: localStorage.getItem('user'),
    companyUsers: [],
    objectUser: [],
    currentUser: null,
    usersType: {
      O: 'Super User',
      1: 'Admin',
      2: 'Client',
    },
    error: null,
    loading: [],
  },
  reducers: {
    setCurrentUserId(state, { payload }) {
      state.currentUserId = payload;
    },
    setCurrentUser(state, { payload }) {
      state.currentUser = payload;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [login.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.data = payload.userInfo;
    },
    [login.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [checkAuth.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [checkAuth.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.checking = false;
      state.data = payload;
    },
    [checkAuth.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
      state.checking = false;
    },

    [getUsersCompany.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getUsersCompany.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.companyUsers = payload;
    },
    [getUsersCompany.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [addUser.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [addUser.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.companyUsers.push(payload.user);
      if (payload.objectUser?.length > 0) {
        state.objectUser.push(payload.user);
      }
    },
    [addUser.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getUser.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.currentUser = payload;
    },
    [getUser.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [deleteUser.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.companyUsers = state.companyUsers.filter((el) => el.user_id !== payload.user_id);
    },
    [deleteUser.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getObjectUser.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getObjectUser.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.objectUser = payload;
    },
    [getObjectUser.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [updateUser.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      const i = state.companyUsers.findIndex((v) => v.user_id === payload.user_id);
      const j = state.objectUser.findIndex((v) => v.user_id === payload.user_id);
      state.companyUsers.splice(i, 1, payload);
      state.objectUser.splice(j, 1, payload);
    },
    [updateUser.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [attachUser.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [attachUser.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      const userToAdd = state.companyUsers.find((v) => v.user_id === payload[0].user_id);
      state.objectUser = state.objectUser.concat(userToAdd);
    },
    [attachUser.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    [detachUser.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [detachUser.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.objectUser = state.objectUser.filter((v) => v.user_id !== payload.user_id);
    },
    [detachUser.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
  },
});
export const { setCurrentUser, setCurrentUserId } = userSlice.actions;
export default userSlice.reducer;
