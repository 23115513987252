import { Edges } from '@react-three/drei';
import { useRef } from 'react';

export const BOX_SIZE = 5;
export const BOX_SIZE_WITH_GAP = BOX_SIZE + 0.4;
export const BOX_SIZE_INNER = BOX_SIZE - 0.2;

export const Box = ({
  opacity = 1, edgesProps = {}, ...props
}) => {
  const ref = useRef();

  return (
    <mesh
      {...props}
      ref={ref}
    >
      <boxGeometry args={[BOX_SIZE, BOX_SIZE, BOX_SIZE]} />
      <meshStandardMaterial opacity={opacity} transparent color="white" />
      <Edges {...edgesProps} />
    </mesh>
  );
};
