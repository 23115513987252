import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import silosMask from '../assets/silosMask.svg';

export const SummaryObjectInfoCard = ({
  round, rect, objectId,
}) => {
  const history = useHistory();

  return (
    <Card
      className="object-card mb-4"
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/summaryObject/${objectId}`);
      }}
    >
      <Card.Body>
        <Card.Title className="mb-2">Сводка</Card.Title>
        <div className="silos-background" style={{ backgroundImage: `url(${silosMask})` }}>
          <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
            <div className="mb-2">
              <span className="me-1">Силос -</span>
              <span>{round}</span>
            </div>

            <div>
              <span className="me-1">Сил. корпус -</span>
              <span>{rect}</span>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
