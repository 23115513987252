import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getObject } from '../api/object';
import { getObjectUser } from '../api/users';
import { Loader, ObjectProperties } from '../components';

export const EditObjectProperties = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { object, loading } = useSelector(({ object }) => object);

  useEffect(() => {
    dispatch(getObject({ object_id: id }));
    dispatch(getObjectUser({ object_id: id }));
  }, [dispatch, id]);

  if (loading?.length > 0) {
    return <Loader />;
  }

  return <ObjectProperties currentObject={object} objectId={id} />;
};
