import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../config/axios';

export const getUsersCompany = createAsyncThunk(
  'user/get',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/user', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getUser = createAsyncThunk(
  'user/getOne',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/user', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const addUser = createAsyncThunk(
  'user/post',
  async (payload, { rejectWithValue }) => {
    try {
      const result = {};
      const { data: user } = await http.post('/user', payload.formData);
      result.user = user;
      if (payload.objectId && user.user_id) {
        const users = [];
        users.push(user.user_id);
        const { data: objectUser } = await http.post('/usersObject',
          { object_id: payload.objectId, user_id: users });
        result.objectUser = objectUser;
      }
      return result;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const updateUser = createAsyncThunk(
  'user/put',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.put('/user', payload);
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.delete('/user', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getObjectUser = createAsyncThunk(
  'user/objectUser',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/user', {
        params: payload,
      });
      return data;
    } catch (error) {
      if (!error.response) throw error;
      return rejectWithValue({
        message: error.response.data,
        status: error.response.status,
      });
    }
  },
);
