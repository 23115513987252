import { createSlice } from '@reduxjs/toolkit';

import {
  addObject,
  getObjects,
  getObject,
  deleteObject,
  getObjectsStatus,
  updateObject,
  getObjectsPreview,
  getObjectsWeather,
  getObjectsSiloses,
  getObjectsSilosKT,
} from '../../api/object';

export const objectSlice = createSlice({
  name: 'objecs',
  initialState: {
    object: {},
    objects: [],
    userList: [],
    attachedUsers: null,
    objectsStatus: {},
    loading: [],
    objectsPreview: {},
    objectsWeather: {},
    objectsSiloses: [],
    silosKT: {},
    country: [
      { name: 'Росcия', value: 'Russia' },
      { name: 'Казахстан', value: 'Kazakhstan' },
      { name: 'Украина', value: 'Ukraine' },
      { name: 'Польша', value: 'Poland' },
    ],
  },
  reducers: {},
  extraReducers: {
    [addObject.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [addObject.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.object = payload.object;
      state.attachedUsers = payload.attachedUsers;
    },
    [addObject.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getObjects.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getObjects.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.objects = payload;
    },
    [getObjects.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
    // Убрал загузку, что бы таблица обьектов долго не грузилась
    [getObjectsStatus.pending]: () => {},
    [getObjectsStatus.fulfilled]: (state, { payload }) => {
      state.objectsStatus = payload;
    },
    [getObjectsStatus.rejected]: (state, { payload, error }) => {
      state.error = payload || error;
    },

    [getObject.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getObject.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.object = payload;
    },
    [getObject.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [updateObject.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [updateObject.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.object = payload;
    },
    [updateObject.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [deleteObject.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [deleteObject.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.objects = state.objects.filter((el) => el.object_id !== payload.object_id);
    },
    [deleteObject.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getObjectsPreview.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getObjectsPreview.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.objectsPreview = payload;
    },
    [getObjectsPreview.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getObjectsWeather.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getObjectsWeather.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.objectsWeather = payload;
    },
    [getObjectsWeather.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getObjectsSiloses.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getObjectsSiloses.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.objectsSiloses = payload;
    },
    [getObjectsSiloses.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },

    [getObjectsSilosKT.pending]: (state) => {
      state.loading = [...state.loading, 'loading'];
    },
    [getObjectsSilosKT.fulfilled]: (state, { payload }) => {
      state.loading = state.loading.slice(0, -1);
      state.silosKT = payload;
    },
    [getObjectsSilosKT.rejected]: (state, { payload, error }) => {
      state.loading = state.loading.slice(0, -1);
      state.error = payload || error;
    },
  },
});

export default objectSlice.reducer;
