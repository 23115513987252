import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteCompany } from '../api/company';
import { deleteObject } from '../api/object';
import { deleteUser } from '../api/users';

export const DeleteModal = (props) => {
  const dispatch = useDispatch();

  const deleteItem = () => {
    switch (props.item) {
    case 'user':
      dispatch(deleteUser({ user_id: props.id }));
      break;
    case 'company':
      dispatch(deleteCompany({ user_group_id: props.id }));
      break;
    case 'object':
      dispatch(deleteObject({ object_id: props.id }));
      break;
    default:
      props.handleClose();
      break;
    }
    props.handleClose();
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Вы действительно хотите удалить
          {' '}
          {props.title}
          ?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Отмена
        </Button>
        <Button variant="danger" onClick={deleteItem}>
          Удалить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
