import { Map, Draggable } from 'pigeon-maps';
import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
} from 'react-bootstrap';
import MaskedInput from 'react-maskedinput';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateObject, addObject } from '../api/object';
import mapPin from '../assets/mapPin.png';
import { getUserGroupId } from '../helpers';
import { UsersTable } from './index';

export const ObjectProperties = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userGroudId = getUserGroupId();

  const [key, setKey] = useState('properties');
  const [objectId, setObjectId] = useState(null);
  const [name, setName] = useState('');
  const [MAC, setMAC] = useState('');
  const [address, setAddress] = useState('');
  const [countrySelect, setCountrySelect] = useState(null);
  const [comment, setComment] = useState('');
  const [doAlertEmails, setDoAlertEmails] = useState(false);
  const [daysToStoreLogs, setDaysToStoreLogs] = useState(365);
  const [objectUsersSelect, setObjectUsersSelect] = useState([]);
  const [anchor, setAnchor] = useState([]);

  const { country, loading } = useSelector(({ object }) => object);
  const { objectUser } = useSelector(({ user }) => user);

  const handleUpdate = (e) => {
    e.preventDefault();

    const data = {
      object_id: objectId,
      MAC,
      name,
      comment,
      client_address: address,
      do_alert_emails: doAlertEmails,
      country: countrySelect ?? country?.[0]?.value,
      days_to_store_logs: daysToStoreLogs,
      user_group_id: userGroudId,
      lat: anchor?.[0],
      lng: anchor?.[1],
    };

    if (!props.objectId) {
      const { object_id, ...d } = data;

      dispatch(addObject({
        object: d,
        users: objectUsersSelect,
      }));
    } else {
      dispatch(updateObject({ data }));
    }

    setTimeout(() => history.push('/objects'), [1000]);
  };

  const handelChangeAnchor = ({ latLng }) => {
    setAnchor(latLng);
  };

  useEffect(() => {
    const i = objectUser.map((el) => String(el.user_id));
    setObjectUsersSelect(i);
  }, [objectUser]);

  useEffect(() => {
    if (props.currentObject) {
      const {
        object_id,
        name, MAC,
        client_address,
        country,
        comment,
        do_alert_emails,
        days_to_store_logs,
        coordinates,
      } = props.currentObject;

      setObjectId(object_id);
      setName(name);
      setMAC(MAC);
      setAddress(client_address);
      setCountrySelect(country);
      setComment(comment);
      setDoAlertEmails(do_alert_emails);
      setDaysToStoreLogs(days_to_store_logs);
      if (coordinates) setAnchor([Number(coordinates.lat), Number(coordinates.lng)]);
    }
  }, [props.currentObject, objectUser]);

  return (
    <Container>
      <Row className="justify-content-center">
        <h2 className="my-3">
          {!props.objectId ? 'Новый объект' : props.currentObject?.name}
        </h2>
      </Row>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab
          eventKey="properties"
          title="Свойства"
          className="pb-1"
        >
          <Form onSubmit={handleUpdate}>
            <fieldset disabled={loading?.length > 0} className="mb-3">
              <Row>
                <Col md={4}>
                  <Form.Group controlId="name">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="MAC">
                    <Form.Label>MAC-адрес</Form.Label>
                    <Form.Control
                      type="text"
                      value={MAC}
                      required
                      onChange={(e) => setMAC(e.target.value)}
                      as={MaskedInput}
                      mask="WW:WW:WW:WW:WW:WW"
                      formatCharacters={{
                        W: {
                          validate(char) { return /\w/.test(char); },
                          transform(char) { return char.toLowerCase(); },
                        },
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={4}>
                  <Form.Group controlId="country">
                    <Form.Label>Страна</Form.Label>
                    <Form.Control
                      as="select"
                      value={countrySelect}
                      onChange={(e) => setCountrySelect(e.target.value)}
                    >
                      {country.map((el) => (
                        <option key={el.name} value={el.value}>{el.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group controlId="address">
                    <Form.Label>Адрес</Form.Label>
                    <Form.Control
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Form.Group controlId="comment">
                    <Form.Label>Комментарий</Form.Label>
                    <Form.Control
                      name="comment"
                      as="textarea"
                      rows={3}
                      value={comment}
                      onChange={(e) => { setComment(e.target.value); }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Map
                    height={300}
                    center={anchor.length > 0 ? anchor : [47.216190, 38.933074]}
                    defaultZoom={11}
                    onClick={handelChangeAnchor}
                    key={`map_${anchor?.[0]}_${anchor?.[1]}`}
                  >
                    <Draggable offset={[16, 32]} anchor={anchor} onDragEnd={setAnchor}>
                      {anchor.length > 0 && <img src={mapPin} width={32} height={32} alt="Pigeon!" />}
                    </Draggable>
                  </Map>

                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12}>
                  <Form.Group controlId="days_to_store_logs" className="d-flex align-items-center">
                    <Form.Label className="m-0 me-3">Хранение логов</Form.Label>
                    <Form.Control
                      name="days_to_store_logs"
                      type="number"
                      value={daysToStoreLogs}
                      onChange={(e) => setDaysToStoreLogs(e.target.value)}
                      style={{ width: '100px' }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-2">
                  <Form.Group controlId="doAlertEmails">
                    <Form.Check
                      type="switch"
                      checked={doAlertEmails}
                      onChange={() => setDoAlertEmails(!doAlertEmails)}
                      label="Уведомления по почте"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group controlId="doAlertSMS">
                    <Form.Check
                      type="switch"
                      name="name"
                      disabled
                      label="Уведомления по SMS"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="submit"
                >
                  Сохранить
                </Button>
              </div>
            </fieldset>
          </Form>
        </Tab>
        <Tab
          eventKey="users"
          title="Пользователи"
          className="pb-1"
          disabled={!props.objectId}
        >
          <UsersTable objectUsers />
        </Tab>
      </Tabs>
    </Container>
  );
};
