import { useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Container,
  Nav,
  Navbar,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';
import { ReactComponent as UserIcon } from '../assets/user.svg';
import { getCurrentStorage, getUserGroupId } from '../helpers';
import { UserModal } from './index';

export const Navigation = () => {
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const userGroupId = getUserGroupId();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { data } = useSelector(({ user }) => user);

  const handleLogout = () => {
    getCurrentStorage().clear();
    window.location.reload();
  };

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg="primary"
      variant="dark"
      fixed="top"
      expanded={expanded}
      // style={{ top: showNavbar && -72 }}
    >
      <Container fluid>
        <Navbar.Brand><img src={logo} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
        { data ? (
          <Navbar.Collapse id="responsive-navbar-nav" className="navbar--mobile">
            <Nav className="ms-auto">
              <NavLink
                to="/objects"
                className="nav-link"
                onClick={() => setExpanded(false)}
              >
                Объекты
              </NavLink>
              <NavLink
                to={{ pathname: '/logs', state: { user_group_id: userGroupId }}}
                className={`nav-link ${(!userGroupId && data.user_type_id === 0) ? 'disabled' : ''}`}
                onClick={() => setExpanded(false)}
              >
                Логи
              </NavLink>
              <NavLink
                to={{ pathname: '/statistics' }}
                className={`nav-link ${(!userGroupId && data.user_type_id === 0) ? 'disabled' : ''}`}
                onClick={() => setExpanded(false)}
              >
                Статистика
              </NavLink>
              <NavLink
                to={{ pathname: '/trends' }}
                className={`nav-link ${(!userGroupId && data.user_type_id === 0) ? 'disabled' : ''}`}
                onClick={() => setExpanded(false)}
              >
                Тренды
              </NavLink>
              { data?.user_type_id === 0 && (<a href="/SuperUserManual.pdf" download className="nav-link">Руководство</a>) }
              { data?.user_type_id === 1 && (<a href="/AdminManual.pdf" download className="nav-link">Руководство</a>) }
              { data?.user_type_id === 2 && (<a href="/ClientManual.pdf" download className="nav-link">Руководство</a>) }
              <div className="d-md-none">
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={handleShow}>
                    Профиль
                  </Button>
                  <Button onClick={handleLogout}>
                    Выйти
                  </Button>
                </div>
              </div>
            </Nav>
            <DropdownButton
              menuAlign="left"
              title={<UserIcon fill="#fff" />}
              className="userDropdown"
            >
              <Dropdown.Item eventKey="1" onClick={handleShow}>Профиль</Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={handleLogout}
                style={{ color: 'var(--red)' }}
              >
                Выйти
              </Dropdown.Item>
            </DropdownButton>
          </Navbar.Collapse>
        ) : null}
      </Container>
      <UserModal
        companyId={userGroupId}
        userId={data?.user_id}
        userType={data?.user_type_id}
        currentUser
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </Navbar>
  );
};
