import overlayFactory from 'react-bootstrap-table2-overlay';
import { BootstrapTableWithRemotePagination } from './BootstrapTableWithRemotePagination';

export const LogsTable = ({
  setCurrentPage,
  currentPage,
  objectLogs,
  totalPages,
  loading,
}) => {
  const columns = [
    {
      dataField: 'object_logs_id',
      text: '#',
    },
    {
      dataField: 'timestamp',
      text: 'Дата/время',
      formatter: (_, row) => new Date(row.timestamp).toLocaleString(),
    },
    {
      dataField: 'status',
      text: 'Статус',
    },
  ];

  const handleTableChange = (_, { page }) => {
    setCurrentPage(page);
  };

  return (
    <div className="mt-3">
      <BootstrapTableWithRemotePagination
        page={currentPage}
        keyField="MAC"
        data={objectLogs || []}
        columns={columns}
        noDataIndication="Записей нет"
        bordered={false}
        hover
        sizePerPage={10}
        totalSize={totalPages}
        onTableChange={handleTableChange}
        loading={loading}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({ ...base, background: 'rgba(206, 206, 206, 0.5)' }),
          },
        })}
      />
    </div>
  );
};
