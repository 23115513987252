/* eslint-disable max-len */
import { Edges, Line } from '@react-three/drei';
import { useState } from 'react';
import { BackSide, DoubleSide } from 'three';
import { getKTColor } from '../../helpers';

const SEGMENTS = 32;

const range = (start, end, size) => {
  const delta = end - start;
  const step = delta / size;
  return [...Array.from({ length: size }).reduce((prev, cur, i) => [...prev, Number((prev[i] + step).toFixed(2))], [start]).slice(0, -1), +end.toFixed(2)];
};

/**
 * Компонент круглого силоса
 * @param {boolean} hasCenter - указывает, имеется ли ценральная термоподвеска у силоса.
 * @param {Array} config - конфигурация силоса
 * @param {Array} temperatures - температуры на датчиках
 * @param {boolean} edges - отображаются ли края заполненности
 * @param {number} h1 - высота цилиндра
 * @param {number} h2 - высота конуса
 * @param {number} vol - заполненность силоса в процентах
 * @param {Record<string, number>} volumes
 * @param {Function} onClickKT - заполненность силоса в процентах
 * @returns {JSX.Element}
 */
export const Silos = ({
  hasCenter, config = [], temperatures, edges = false, h1 = 3, h2 = 1, vol = 1, onClickKT,
}) => {
  if (vol > 100) {
    vol = 100;
  }

  const CYLINDER_HEIGHT = h1 * config.length;
  const CONE_HEIGHT = h2 * config.length;
  const HEIGHT = CYLINDER_HEIGHT + CONE_HEIGHT;

  const SILOS_RADIUS = config.length + 1;
  const SILOS_RADIUS_INNER = SILOS_RADIUS - 0.1;
  const SILOS_RADIUS_INNER_2 = SILOS_RADIUS / config.length * config.length - 1;

  const CYLINDER_MAX_VOLUME = Math.PI * SILOS_RADIUS * SILOS_RADIUS * CYLINDER_HEIGHT;
  const CONE_MAX_VOLUME = (Math.PI * SILOS_RADIUS * SILOS_RADIUS * CONE_HEIGHT) / 3;

  const CYLINDER_MAX_VOLUME_PER = CYLINDER_MAX_VOLUME / (CYLINDER_MAX_VOLUME + CONE_MAX_VOLUME) * 100;
  const CONE_MAX_VOLUME_PER = 100 - CYLINDER_MAX_VOLUME_PER;

  const CYLINDER_VOLUME_PER = vol <= CYLINDER_MAX_VOLUME_PER ? CYLINDER_HEIGHT * vol / CYLINDER_MAX_VOLUME_PER : (CYLINDER_HEIGHT - 0.0001) * CYLINDER_MAX_VOLUME_PER / CYLINDER_MAX_VOLUME_PER;
  const CONE_VOLUME_PER = vol > CYLINDER_MAX_VOLUME_PER ? (CONE_HEIGHT - 0.1) * ((vol - CYLINDER_MAX_VOLUME_PER) / CONE_MAX_VOLUME_PER) : 0;

  const LINE_HEIGHTS = range(0, CONE_HEIGHT, config?.length + 1);

  const getSensorsData = (sensorsData) => {
    if (Array.isArray(sensorsData)) {
      return sensorsData;
    }

    return [];
  };

  const DELTA_PER = (h2 / h1) / 3;

  const CYLINDER_VOLUME_PER_L = CONE_VOLUME_PER === 0 ? CYLINDER_VOLUME_PER - (CYLINDER_VOLUME_PER * DELTA_PER) : CYLINDER_VOLUME_PER;

  const CONF = (hasCenter ? config?.slice(1) : config);

  const CENTER = 0;

  const [selectedIndex, setSelectedIndex] = useState([]);

  return (
    <>
      {CONF?.reverse().map((radius, radiusIndex, { length: radiusesLength }) => radius?.map((r, ri) => {
        const x = SILOS_RADIUS_INNER_2 * Math.cos(ri * 2 * Math.PI / radius.length) * ((radiusesLength - radiusIndex) / radiusesLength);
        const z = SILOS_RADIUS_INNER_2 * Math.sin(ri * 2 * Math.PI / radius.length) * ((radiusesLength - radiusIndex) / radiusesLength);

        const data = Array.isArray(temperatures?.sensors_data?.[r.addr || '']) ? temperatures?.sensors_data?.[r.addr || ''] : [9999];

        const start = -HEIGHT / 2;
        const stop = CYLINDER_HEIGHT + start + LINE_HEIGHTS[radiusIndex + 1];

        const y = range(start, stop, data?.length).reverse();

        // const volume = +Number(volumes[r.addr || '']?.volume).toFixed(2);

        return data?.map((_d, di) => (
          <>
            {/* <mesh */}
            {/*  position={[x, volumeCoord, z]} */}
            {/*  rotation={[Math.PI * 3 / 2, 0, 0]} */}
            {/* > */}
            {/*  <circleGeometry args={[0.2, 10]} /> */}
            {/*  <meshBasicMaterial opacity={0.35} transparent color="orange" /> */}
            {/* </mesh> */}
            <Line
              key={`line${radiusIndex}_${ri}_${di}`}
              points={[[x, y[di], z], [x, y[di + 1], z]]}
              color={_d === 9999 ? 'black' : getKTColor({
                sensors_data: { [r.addr]: [_d]},
                options: temperatures?.options,
              }, r, true) ?? 'black'}
              lineWidth={selectedIndex[0] === radiusIndex && selectedIndex[1] === ri ? 5 : 2}
              onPointerUp={(e) => {
                if (e.button === 0) {
                  setSelectedIndex([radiusIndex, ri]);
                  onClickKT(r);
                }
              }}
            />
          </>
        ));
      }))}
      <mesh position={[CENTER, -CONE_HEIGHT / 2, CENTER]}>
        <cylinderGeometry args={[SILOS_RADIUS, SILOS_RADIUS, CYLINDER_HEIGHT, SEGMENTS, 1, false]} />
        <meshStandardMaterial
          side={BackSide}
          opacity={0.35}
          transparent
          color="white"
        />
      </mesh>
      {CONE_VOLUME_PER === 0 && (
        <>
          <mesh position={[
            CENTER,
            (-CYLINDER_HEIGHT - CONE_HEIGHT + CYLINDER_VOLUME_PER_L) / 2 + CYLINDER_VOLUME_PER_L / 2 + CYLINDER_VOLUME_PER * (DELTA_PER * 3) / 2,
            CENTER,
          ]}
          >
            <coneGeometry args={[
              SILOS_RADIUS_INNER,
              CYLINDER_VOLUME_PER * (DELTA_PER * 3),
              SEGMENTS,
              1, true]}
            />
            <meshBasicMaterial
              opacity={0}
              transparent
              wireframe={edges}
              color="orange"
            />
            {edges && (
              <Edges
                color="orange"
              />
            )}
          </mesh>
          <mesh position={[
            CENTER,
            (-CYLINDER_HEIGHT - CONE_HEIGHT + CYLINDER_VOLUME_PER_L) / 2 + CYLINDER_VOLUME_PER_L / 2 + CYLINDER_VOLUME_PER * (DELTA_PER * 3) / 2,
            CENTER,
          ]}
          >
            <coneGeometry args={[
              SILOS_RADIUS_INNER,
              CYLINDER_VOLUME_PER * (DELTA_PER * 3),
              SEGMENTS,
              1, true]}
            />
            <meshBasicMaterial
              opacity={0.35}
              transparent
              color="orange"
            />
            {edges && (
              <Edges
                color="orange"
              />
            )}
          </mesh>
        </>
      )}
      <mesh
        position={[
          CENTER,
          (-CYLINDER_HEIGHT - CONE_HEIGHT + CYLINDER_VOLUME_PER_L) / 2,
          CENTER]}
      >
        <cylinderGeometry args={[
          SILOS_RADIUS_INNER,
          SILOS_RADIUS_INNER,
          CYLINDER_VOLUME_PER_L,
          SEGMENTS, 1, true]}
        />
        <meshBasicMaterial
          side={BackSide}
          opacity={0.35}
          transparent
          color="orange"
        />
        {edges && <Edges color="orange" />}
      </mesh>
      {/* {CONE_VOLUME_PER === 0 && CONF.map((_, i, { length }) => ( */}
      {/*  <mesh position={[ */}
      {/*    0, (-CYLINDER_HEIGHT - CONE_HEIGHT + CYLINDER_VOLUME_PER_L) / 2 + CYLINDER_VOLUME_PER_L / 2, 0, */}
      {/*  ]} */}
      {/*  > */}
      {/*    <cylinderGeometry args={[ */}
      {/*      SILOS_RADIUS_INNER / (i + 1 / length + 1), */}
      {/*      SILOS_RADIUS_INNER, */}
      {/*      CYLINDER_VOLUME_PER * 0.2, */}
      {/*      SEGMENTS, 1, true]} */}
      {/*    /> */}
      {/*    <meshBasicMaterial */}
      {/*      side={DoubleSide} */}
      {/*      opacity={0.35} */}
      {/*      transparent */}
      {/*      color="orange" */}
      {/*    /> */}
      {/*    {edges && <Edges color="orange" />} */}
      {/*  </mesh> */}
      {/* ))} */}

      <mesh position={[CENTER, CYLINDER_HEIGHT / 2, CENTER]}>
        <coneGeometry args={[SILOS_RADIUS, CONE_HEIGHT, SEGMENTS, SEGMENTS]} />
        <meshBasicMaterial
          side={BackSide}
          opacity={0.35}
          transparent
          color="white"
        />
      </mesh>
      {CONE_VOLUME_PER > 0 && (
        <>
          <mesh position={[CENTER, (-CONE_HEIGHT + CYLINDER_VOLUME_PER + CONE_VOLUME_PER) / 2, CENTER]}>
            <coneGeometry args={[SILOS_RADIUS_INNER, CONE_VOLUME_PER, SEGMENTS, 1, true]} />
            <meshBasicMaterial
              opacity={0}
              transparent
              wireframe={edges}
              color="orange"
            />
            {edges && <Edges color="orange" />}
          </mesh>
          <mesh position={[CENTER, (-CONE_HEIGHT + CYLINDER_VOLUME_PER + CONE_VOLUME_PER) / 2, CENTER]}>
            <coneGeometry args={[SILOS_RADIUS_INNER, CONE_VOLUME_PER, SEGMENTS, 1, true]} />
            <meshBasicMaterial
              side={DoubleSide}
              opacity={0.35}
              transparent
              color="orange"
            />
            {edges && <Edges color="orange" />}
          </mesh>
        </>
      )}
      {CYLINDER_VOLUME_PER > 0 && (
        <mesh
          position={[CENTER, -(CYLINDER_HEIGHT + CONE_HEIGHT) / 2, CENTER]}
          rotation={[Math.PI * 3 / 2, 0, 0]}
        >
          <circleGeometry args={[SILOS_RADIUS_INNER, SEGMENTS]} />
          <meshBasicMaterial opacity={0.35} transparent color="orange" />
        </mesh>
      )}
      {hasCenter
        && config?.[0]?.[0]
        && getSensorsData(temperatures?.sensors_data?.[config[0][0].addr || ''])?.map((_d, dIndex, arr) => {
          const x = CENTER;
          const z = CENTER;
          const y = range(-HEIGHT / 2, HEIGHT / 2, arr?.length).reverse();

          return (
            <Line
              points={[
                [x, y[dIndex], z],
                [x, y[dIndex + 1], z],
              ]}
              lineWidth={2}
              color={getKTColor({
                sensors_data: { [config[0][0].addr]: [_d]},
                options: temperatures?.options,
              }, config[0][0], true) ?? 'black'}
              onPointerUp={(e) => {
                if (e.button === 0) {
                  onClickKT(config[0][0]);
                }
              }}
            />
          );
        })}
    </>
  );
};
