import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login } from '../api/auth';
import { ReactComponent as ShowIcon } from '../assets/view.svg';
import { ReactComponent as HideIcon } from '../assets/visibility.svg';
import { convertFormDataToObject } from '../helpers';

export const FormLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(({ user }) => user);
  const [formData, setFormData] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user.data) history.push('/objects');
  }, [history, user.data]);

  const handleFormChange = (e) => {
    setFormData(convertFormDataToObject(e.target.form));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(formData));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      onChange={handleFormChange}
    >
      <h1 style={{ textAlign: 'center' }}>Войти в систему</h1>
      <Form.Group controlId="login">
        <Form.Label>Логин</Form.Label>
        <Form.Control
          name="login"
          type="text"
          isInvalid={user.error && user.error.status !== 403}
          required
        />
      </Form.Group>
      <Form.Group controlId="password" className="mt-2">
        <Form.Label>Пароль</Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            name="password"
            isInvalid={user.error && user.error.status !== 403}
            required
          />

          <Button
            className="showPasswordBtn d-flex align-items-center"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {
              (showPassword)
                ? (
                  <HideIcon style={{
                    width: '16px', height: '16px', cursor: 'pointer',
                  }}
                  />
                )
                : (
                  <ShowIcon style={{
                    width: '16px', height: '16px', cursor: 'pointer',
                  }}
                  />
                )
            }
          </Button>

          <Form.Control.Feedback type="invalid">Email или пароль неверные</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="stayLoggedIn" className="mt-2">
        <Form.Check
          name="stayLoggedIn"
          type="checkbox"
          label="Запомнить меня"
          className="ps-0 customCheckboxRadio"
        />
      </Form.Group>
      <Button
        block
        variant="primary"
        type="submit"
        className="mt-2 w-100"
      >
        Войти
      </Button>
    </Form>
  );
};
