export const convertTempStringToArray = (str) => {
  if (!str || str === '-') {
    return [];
  }

  if (str.length % 4 !== 0) {
    return Array.from({ length: Math.ceil(str.length / 4) }).map(() => '0');
  }

  const SENSOR_DATA_SIZE = 4;
  const subarray = [];

  for (let i = 0; i < Math.ceil(str.length / SENSOR_DATA_SIZE); i += 1) {
    const sensorData = str.slice((i * SENSOR_DATA_SIZE), (i * SENSOR_DATA_SIZE) + SENSOR_DATA_SIZE);

    if (+sensorData === 0) {
      subarray[i] = '0';
    } else if (sensorData[0] === '0') {
      subarray[i] = `+${+sensorData.slice(1, 3)}.${sensorData.slice(3, 4)}`;
    } else if (sensorData[0] === '1' || sensorData[0] === '-') {
      subarray[i] = `-${+sensorData.slice(1, 3)}.${sensorData.slice(3, 4)}`;
    } else {
      subarray[i] = 'Н/Д';
    }

    // const isNumber = sensorData.split('').every((v) => Number.isInteger(+v));

    // if (+sensorData === 0 && isNumber) {
    //   subarray[i] = '0';
    // } else if (sensorData === 'GGGG') {
    //   subarray[i] = 'Н/Д';
    // } else if (sensorData[0] === '0' && isNumber) {
    //   subarray[i] = `+${+sensorData.slice(1, 3)}.${sensorData.slice(3, 4)}`;
    // } else if (sensorData[0] === '1' && isNumber) {
    //   subarray[i] = `-${+sensorData.slice(1, 3)}.${sensorData.slice(3, 4)}`;
    // } else {
    //   subarray[i] = 'Н/Д';
    // }
  }

  return subarray;
};
