import { Modal } from 'react-bootstrap';
import { FormConfig } from './FormConfig';

export const NotConfiguredObjectModal = (props) => (
  <Modal
    show={props.show}
    onHide={props.handleClose}
    size="md"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Новый силос</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FormConfig handelCloseModel={props.handleClose} />
    </Modal.Body>
  </Modal>
);
