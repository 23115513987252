import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CompanyProperies } from '../components';
import { setCurrentCompany } from '../store/reducers/company';

export const AddCompany = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentCompany({}));
  }, [dispatch]);

  return <CompanyProperies />;
};
