/* eslint-disable max-len */

import isEmpty from 'lodash/isEmpty';
import { useState, useMemo } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getLogsKT } from '../api/logs';
import { useFullObjectsData } from '../queries';

const formData = (object_id, list, silos_name, from, to) => ({
  object_id,
  list,
  silos_name,
  from,
  to,
});

export const FilterChart = () => {
  const dispatch = useDispatch();

  const [currentObject, setCurrentObject] = useState(null);
  const [currentSilos, setCurrentSilos] = useState(null);
  const [curKT, setCurKT] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const data = useMemo(() => formData(currentObject, curKT, currentSilos, dateFrom, dateTo),
    [currentObject, curKT, currentSilos, dateFrom, dateTo]);

  const {
    objects,
  } = useSelector(({ object }) => object);

  const objectsIds = useMemo(() => objects
  && objects?.map((object) => object.object_id), [objects]);

  const { data: objectsData } = useFullObjectsData(objectsIds);

  const handleSubmit = () => {
    dispatch(getLogsKT(data));
  };

  const objectsSiloses = useMemo(() => objectsData?.filter((object) => object.object_id === Number(currentObject)), [objectsData, currentObject]);

  const silosKT = useMemo(() => objectsSiloses?.find((silos) => silos.name === currentSilos)?.options, [objectsSiloses, currentSilos]);

  return (
    <Row className="mt-3 mb-3">
      <Col xs={12} md={6} lg={3} className="mb-3">
        <Form.Group controlId="formGridState">
          <Form.Control
            as="select"
            defaultValue={currentObject || 'Выберите объект'}
            value={currentObject}
            disabled={isEmpty(objects)}
            onChange={(e) => {
              setCurrentObject(e.target.value);
              setCurrentSilos('Выберите силос');
              setCurKT('Выберите подвеску');
            }}
          >
            <option disabled>Выберите объект</option>
            {objects.map((el) => (
              <option key={el.object_id} value={el.object_id}>{el.name}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs={12} md={6} lg={3} className="mb-3">
        <Form.Group controlId="formGridState">
          <Form.Control
            as="select"
            defaultValue={currentSilos || 'Выберите силос'}
            value={currentSilos}
            disabled={isEmpty(objectsSiloses)}
            onChange={(e) => {
              setCurrentSilos(e.target.value);
              setCurKT('Выберите подвеску');
            }}
          >
            <option disabled>Выберите силос</option>
            {objectsSiloses?.map((objectsSilos) => (
              <option value={objectsSilos.name}>
                {objectsSilos.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs={12} md={6} lg={3} className="mb-3">
        <Form.Group controlId="formGridState">
          <Form.Control
            as="select"
            defaultValue={curKT || 'Выберите подвеску'}
            value={curKT}
            disabled={isEmpty(silosKT)}
            onChange={(e) => {
              setCurKT(e.target.value);
            }}
          >
            <option disabled>Выберите подвеску</option>
            {silosKT && silosKT.map((kt) => (kt.KT_address ? (
              <option
                key={kt.KT_address}
                value={kt.KT_address}
              >
                {kt.KT_address}
              </option>
            ) : null
            ))}
          </Form.Control>
        </Form.Group>
      </Col>

      <Col xs={12} md={6} lg={3}>
        <Row>
          <Col className="mb-3">
            <Form.Group
              className="d-flex align-items-center pe-0"
              controlId="dateFrom"
            >
              <Form.Label className="mb-0 me-1">c</Form.Label>
              <Form.Control
                type="datetime-local"
                defaultValue={null || 'Выберите дату начала'}
                disabled={!curKT || curKT === 'Выберите подвеску'}
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col className="mb-3">
            <Form.Group
              className="d-flex align-items-center pe-0"
              controlId="dateFrom"
            >
              <Form.Label className="mb-0 me-1">по</Form.Label>
              <Form.Control
                type="datetime-local"
                defaultValue={null}
                disabled={!dateFrom}
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="text-right">
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={!dateTo}
              >
                Показать
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
